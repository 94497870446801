// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .fontSize62 {
        font-size: 40px !important;
    }

    .mobilemainbrand>div {
        width: 100%;
    }

    .mobilemainbrand img {
        display: none;
    }

    .expertiesMobile {
        flex-direction: column;
        padding: 0px !important;
    }

    .expertiesBtnMobile {
        top: 18%;
        left: -5px;
    }

    .mobilepadding {
        padding: 25px !important;
    }

    .mobilecount {
        /* flex-wrap: wrap; */
        gap: 20px;
        flex-direction: column;
    }

    .mobilecount>div {
        width: 100%;
        flex-direction: column;
    }

    .campaignMobile {
        flex-direction: column;
        align-items: end;
    }
    .fontSize40{
        font-size: 30px !important;
    }
    .fontSize18{
        font-size: 15px !important;
    }
    .py80{
        padding: 40px 0px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .mobilemainbrand>div {
        width: 100%;
    }

    .mobilemainbrand img {
        display: none;
    }

    .expertiesMobile {
        flex-direction: column;
    }

    .mobilepadding {
        padding: 25px !important;
    }

    .mobilecount {
        flex-wrap: wrap;
    }

    .mobilecount>div {
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/mediaquery.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;IAC9B;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;IAEA;QACI,QAAQ;QACR,UAAU;IACd;;IAEA;QACI,wBAAwB;IAC5B;;IAEA;QACI,qBAAqB;QACrB,SAAS;QACT,sBAAsB;IAC1B;;IAEA;QACI,WAAW;QACX,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;QACtB,gBAAgB;IACpB;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,wBAAwB;IAC5B;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .fontSize62 {\n        font-size: 40px !important;\n    }\n\n    .mobilemainbrand>div {\n        width: 100%;\n    }\n\n    .mobilemainbrand img {\n        display: none;\n    }\n\n    .expertiesMobile {\n        flex-direction: column;\n        padding: 0px !important;\n    }\n\n    .expertiesBtnMobile {\n        top: 18%;\n        left: -5px;\n    }\n\n    .mobilepadding {\n        padding: 25px !important;\n    }\n\n    .mobilecount {\n        /* flex-wrap: wrap; */\n        gap: 20px;\n        flex-direction: column;\n    }\n\n    .mobilecount>div {\n        width: 100%;\n        flex-direction: column;\n    }\n\n    .campaignMobile {\n        flex-direction: column;\n        align-items: end;\n    }\n    .fontSize40{\n        font-size: 30px !important;\n    }\n    .fontSize18{\n        font-size: 15px !important;\n    }\n    .py80{\n        padding: 40px 0px;\n    }\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n    .mobilemainbrand>div {\n        width: 100%;\n    }\n\n    .mobilemainbrand img {\n        display: none;\n    }\n\n    .expertiesMobile {\n        flex-direction: column;\n    }\n\n    .mobilepadding {\n        padding: 25px !important;\n    }\n\n    .mobilecount {\n        flex-wrap: wrap;\n    }\n\n    .mobilecount>div {\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
