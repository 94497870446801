import * as React from 'react';
import { Box, TextField, Button, Typography, Container, Grid, Checkbox, FormControlLabel, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import Snackbar from './snackbar';
import emailjs from 'emailjs-com';
const StyledInputLabel = styled(InputLabel)({
    fontSize: '18px', // Change the font size of the label
});

const StyledSelect = styled(Select)({
    fontSize: '16px', // Change the font size of the text inside the select
});
export default function ContactUs() {
    const [open, setOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        country: 'IN',
        phoneNumber: '',
        message: '',
        agreeToPolicy: false,
    });
    function submitformHandler(e) {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_daj4vrj', 'template_ay1exge', e.target, '-z__4wjPqbGL513C-')
            .then((result) => {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    country: 'IN',
                    phoneNumber: '',
                    message: '',
                    agreeToPolicy: false,
                })
                setSnackMessage('Thank you for reaching out to us! We appreciate your interest, we will get back to you soon.')
                setOpen(true)
                setSeverity('success')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                setSnackMessage('Error sending email')
                setSeverity('error')
                setOpen(true)
                console.log(error.text);
            });
     
    };
    return (
        <Box className='bgImg py80' id="contact">
            <Container className='p-0 mobilepadding' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <Grid container  >
                    <Grid item xs={12} md={6} className='flexDirectionColumn displayStyle1'>
                        <Typography variant="h4" component="h1" className='fontSize40 font600 colorWhite' gutterBottom>
                            Ready to Transform Your Brand?
                        </Typography>
                        <Typography variant="body1" className='fontSize16 font500 colorWhite' sx={{ my: 3, width: '90%' }}>
                            Connect with us to start your journey toward impactful brand marketing!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className='borderRadius pad-35 bgWhite'>
                        <Typography variant="h5" component="h2" className='fontSize20 font600' gutterBottom>
                            Connect With Us
                        </Typography>
                        <Typography variant="body2" gutterBottom className='fontSize14 font400' sx={{ color: '#667085', mb: 3 }} >
                            Interested in our partner solutions? Fill out the form below
                        </Typography>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={submitformHandler}
                                        InputProps={{ style: { fontSize: '16px' } }}
                                        InputLabelProps={{ style: { fontSize: '16px' } }}
                                        fullWidth label="First name" variant="outlined" required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={submitformHandler}
                                        InputProps={{ style: { fontSize: '16px' } }}
                                        InputLabelProps={{ style: { fontSize: '16px' } }}
                                        fullWidth label="Last name" variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        value={formData.email}
                                        onChange={submitformHandler}
                                        InputProps={{ style: { fontSize: '16px' } }}
                                        InputLabelProps={{ style: { fontSize: '16px' } }}
                                        fullWidth label="Email" type="email" variant="outlined" required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <StyledInputLabel>Country</StyledInputLabel>
                                        <StyledSelect
                                            name="country"
                                            value={formData.country}
                                            onChange={submitformHandler}
                                            label="Country"
                                        >
                                            <MenuItem value="IN">IN</MenuItem>
                                        </StyledSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={submitformHandler}
                                        InputProps={{ style: { fontSize: '16px' } }}
                                        InputLabelProps={{ style: { fontSize: '16px' } }}
                                        fullWidth label="Phone number" type="number" variant="outlined" required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="message"
                                        value={formData.message}
                                        onChange={submitformHandler}
                                        InputProps={{ style: { fontSize: '16px' } }}
                                        InputLabelProps={{ style: { fontSize: '16px' } }}
                                        fullWidth label="Message" variant="outlined" multiline rows={4}
                                        required />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="agreeToPolicy" checked={formData.agreeToPolicy} onChange={submitformHandler} />}
                                        label={<Typography className='fontSize14 font400'>You agree to our friendly privacy policy.</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth variant="contained" className='buttonGradient borderRadius boxshadow font600 fontSize16' sx={{ height: '43px' }} type="submit">
                                        Submit Now
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar severity={severity} open={open} setOpen={setOpen} snackMessage={snackMessage} />
        </Box>
    );
}
