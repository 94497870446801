
import React, { useEffect } from 'react';
import {
	Route,
	Redirect,
	useLocation
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Sidebar from '../src/admin/header/sidebar';
import Footer from './admin/footer';
import { isSelfLogin } from './_helpers/auth/secureToken'
import useMediaQuery from '@mui/material/useMediaQuery';
import ArtistBackgroundImg from "./img/bg_image_texture4.png"

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);

function PrivateRoute({ children, loginStatus, ...rest }) {
	let location = useLocation();
	const mediumViewport = useMediaQuery('(max-width:768px)');
	const [open, setOpen] = React.useState(true);
	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const handleReleaseSongOpen = () => {
		setOpen(!open);
	};
	React.useEffect(()=>{
		mediumViewport?setOpen(false):setOpen(true)
	},[mediumViewport]) //check the view port and set the toggle open and true
	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}));


	return (
		isSelfLogin() ?
			<Route
				{...rest}
				render={({ location }) =>
				(
					<div>
						<Box sx={{ display: 'flex' }} className="h-100 bodyColor">
							<Sidebar open={open} setOpen={setOpen} handleReleaseSongOpen={handleReleaseSongOpen} handleDrawerOpen={handleDrawerOpen}/>
							<Main open={open} className="w-100 min-vh-100 p-0" sx={{background:`linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), url(${ArtistBackgroundImg})`}}>
								<DrawerHeader className="p-0" />
								{children}
								<Footer />
							</Main>
							{/* </div> */}
						</Box>
					</div>

				)
				}
			/>
			: (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: location }
					}}
				/>

			)


	);

}
export default PrivateRoute;
