import React from 'react'
import pageNotFoundImg from '../img/PageNotFound.png'
import backgroundImage from '../img/bg_image_texture4.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link} from 'react-router-dom'
import { useMediaQuery } from '@mui/material';

const PageNotFound = () => {
  let smScreen = useMediaQuery('(max-width:768px)')

  return (
    <div className="d-flex  align-items-center vh-100" style={{backgroundImage:`url(${backgroundImage})`,backgroundRepeat:'no-repeat',backgroundSize:'contain'}}>
      <div
        className="text-center"
        style={{
          left: !smScreen ? '13%' : '',
          position: 'absolute',
          top:'13%',
        }}
      >
          <img src={pageNotFoundImg} alt="Img Not found" height='50%' width='50%' />
            {!smScreen ? (
              <h1 className="mt-4" style={{ fontWeight: '900' }}>
                Oops, We can not seem to find <br /> the page what you are looking for.
              </h1>
            ) : (
              <h3 className="mt-4" style={{ fontWeight: '900' }}>Oops, We can not seem to find <br /> the page what you are looking for.
              </h3>
            )}
          <div className="m-4"></div>
          <h5 className='mb-4 mt-4 mx-2'>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h5>
          <Link to="/"><button className='gn-actionbtn mt-4'><ArrowBackIcon /> Back To Home Page</button></Link>
      </div>
    </div>
  )
}

export default PageNotFound