import React, { useState } from 'react';
import { Box, IconButton, Card, CardMedia, Typography, Container, CardContent, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import slider1 from '../img-landing/slider/slider1.png';
import slider2 from '../img-landing/slider/slider2.png';
import slider3 from '../img-landing/slider/slider3.png';
import slider4 from '../img-landing/slider/slider4.png';
import slider5 from '../img-landing/slider/slider5.png';
import slider6 from '../img-landing/slider/slider6.png';
import slider7 from '../img-landing/slider/slider7.png';

const images = [
    { id: "1", image: slider7, label: 'Brand or Product Shoot with Model', value: 'High-quality visuals and storytelling to showcase your product. ' },
    { id: "2", image: slider1, label: 'Influencer Product Campaign ', value: 'Pair your products with the right influencers for authentic promotion. ' },
    { id: "3", image: slider3, label: 'Event-based Activation ', value: 'Engage directly with audiences in high-energy events. ' },
    { id: "4", image: slider2, label: 'Music Video & Influencer Campaign ', value: 'Blend music videos with influencer partnerships for dual impact. ' },
    { id: "5", image: slider6, label: 'Music IPs Creation & Management ', value: 'Develop, manage, market exclusive music IPs comprehensively.' },
    // { id: "4", image: slider4, label: 'Custom Songs (Audio only) ', value: "Create bespoke audio tracks that reflect your brand's ethos." },
    // { id: "5", image: slider5, label: 'College Fest Activation ', value: 'Target the youth by integrating your brand into college festivals. ' },
];

const CustomSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const getCardWidth = () => {
        if (isMobile) return '100%'; // Adjust this percentage as needed for mobile
        if (isTablet) return '50%'; // Adjust this percentage as needed for tablet
        return '23%'; // Default width for larger screens
    };

    const handleNext = () => {
        if (currentIndex < images.length - (isMobile ? 1 : isTablet ? 2 : 4)) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    return (
        <Box className='containerbgColor py80'>
            <Container className='p-0 mobilepadding'>
                <Box className='displayStyle justifySpacebtw campaignMobile'>
                    <Box>
                        <Typography variant="h4" className='font600 fontSize40' gutterBottom>
                            Campaigns Designed for Impact
                        </Typography>
                        <Typography variant="body1" paragraph className='fontSize18 font400 lineHeight'>
                            Choose How You Want to Connect with Your Audience
                        </Typography>
                    </Box>
                    <Box className='displayStyle' gap={2}>
                        <IconButton className={(currentIndex === 0 ? "bgWhite colorblack" : "buttonGradient colorWhite") + ' borderRadius slidbtn '} onClick={handlePrev} disabled={currentIndex === 0}>
                            <ArrowBackIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton className={(currentIndex === images.length - (isMobile ? 1 : isTablet ? 2 : 4) ? "bgWhite colorblack" : "buttonGradient colorWhite") + ' borderRadius slidbtn '} onClick={handleNext} disabled={currentIndex === images.length - (isMobile ? 1 : isTablet ? 2 : 4)}>
                            <ArrowForwardIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" mt={5}>
                    <Box display="flex" overflow="hidden" width="100%">
                        <Box display="flex" gap={'31px'}
                            sx={{
                                // transform: `translateX(-${currentIndex * (isMobile ? 100 : isTablet ? 50 : 25)}%)`,
                                transform: `translateX(-${currentIndex * 25}%)`,
                                transition: 'transform 0.5s ease',
                            }}>
                            {images.map((img, index) => (
                                <Card key={index} className='boxshadownone borderRadius' 
                                // style={{ minWidth: getCardWidth() }}
                                style={{ minWidth: '23%' }}>
                                    <CardMedia component="img" image={img.image} alt={`Image ${index + 1}`} />
                                    <CardContent>
                                        <Typography className='font600 fontSize20'>{img.label}</Typography>
                                        <Typography className='font400 fontSize18 lineHeight textColor' mt={2}>{img.value}</Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default CustomSlider;

