export const formSnackbar = {
  profileSuccess:{
    successMessage:'Profile details save successfully',
    successBrandMessage:'Brand details save successfully.',
    successOfferMessage:'Offer details save successfully.',
    successGigMessage:'GigWork created successfully.',
  },
  errors: {
    errorMsg: 'Something went wrong! Your request has failed.',
  },
}
