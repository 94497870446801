import React, { useState } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";
import { Button, TextField, Box, Typography, IconButton } from "@mui/material";
import LogoApp from "../../img/logoGntunes.svg";
import Footer from "../footer";
import backgroundImage from '../../img/bg_image_texture4.png';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useHistory } from "react-router-dom";
import { setLoader } from "../../redux";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import ApiService from "../../_services/ApiService";
import { RegisterUser } from "../../_services/register";
// import { RegisterUser } from "../../_services/register";

const EmailPasswordFirebaseSignup = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [showpassword, setShowpassword] = useState(false);
    const [showconfirmpassword, setShowConfirmpassword] = useState(false);
    const [errors, setErrors] = useState({ email: "", password: "", confirmpassword: "" });

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePassword = (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);


    async function registerClickHandler(uid) {
        dispatch(setLoader(true));
        try {
            const response = await RegisterUser({ brand_auth: uid, policy:'True' })
            if (response.status === 200) {
                setTimeout(() => history.push("/thank-you"), 1500);
            }
            dispatch(setLoader(false));
        }
        catch (error) {
            console.error("Error during login:", error);
        } finally {
            dispatch(setLoader(false));
        }
    }

    const handleEmailSignup = async () => {
        let valid = true;
        let newErrors = { email: "", password: "", confirmpassword: "" };

        if (!email) {
            newErrors.email = "Email is required.";
            valid = false;
        } else if (!validateEmail(email)) {
            newErrors.email = "Invalid email format.";
            valid = false;
        }

        if (!password) {
            newErrors.password = "Password is required.";
            valid = false;
        } else if (!validatePassword(password)) {
            newErrors.password = "Password must be at least 8 characters, include 1 uppercase, 1 lowercase, 1 number, and 1 special character.";
            valid = false;
        }

        if (!confirmpassword) {
            newErrors.confirmpassword = "Confirm Password is required.";
            valid = false;
        } else if (password !== confirmpassword) {
            newErrors.confirmpassword = "Passwords do not match.";
            valid = false;
        }

        setErrors(newErrors);

        if (valid) {
            dispatch(setLoader(true));
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                // Send Email Verification
                dispatch(setLoader(false));
                await sendEmailVerification(user);
                console.log("Signup successful, verification email sent.", user);
                await registerClickHandler(user?.uid)
            } catch (error) {
                console.error("Error signing up with email:", error.message);
                dispatch(setLoader(false));
                setErrors({ ...newErrors, email: error.message });
            }
        }
    };

    return (
        <Box className="container-fluid" style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <Box className="row">
                <nav className="navbar navbar-light border pb-0">
                    <Box className="container-fluid">
                        <a className="navbar-brand p-3" href="https://brands.groovenexus.com/">
                            <img src={LogoApp} alt="logo" />
                        </a>
                    </Box>
                </nav>
                <Box style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
                    <Box className="mt-4 mb-4 p-4 border rounded shadowCustom bg-white">
                        <form className="row g-3">
                            <h4 className="text-center">Email Sign Up</h4>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                                size="small"
                                error={!!errors.email}
                                helperText={errors.email}
                            />

                            <TextField
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                size="small"
                                type={showpassword ? 'text' : 'password'}
                                error={!!errors.password}
                                helperText={errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setShowpassword(!showpassword)}>
                                            {showpassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    ),
                                }}
                            />

                            <TextField
                                label="Confirm Password"
                                value={confirmpassword}
                                onChange={(e) => setConfirmpassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                size="small"
                                type={showconfirmpassword ? 'text' : 'password'}
                                error={!!errors.confirmpassword}
                                helperText={errors.confirmpassword || "Password must match the above field. Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character."}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setShowConfirmpassword(!showconfirmpassword)}>
                                            {showconfirmpassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    ),
                                }}
                            />

                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="body2" sx={{ width: '55%' }}>
                                    <span> Already have an account? </span>
                                    <Link to={{ pathname: "/login", state: { openEmailPhone: true } }} className="gn-actionbtn-color fw-bold text-decoration-none">
                                        Sign In
                                    </Link>
                                    {/* {' '}or
                                    <a className="gn-actionbtn-color fw-bold text-decoration-none" href="/connect-audio-platform"> Connect with audio platform</a> */}
                                </Typography>
                                <Button
                                    variant="contained"
                                    className='gn-actionbtn px-3 ms-3'
                                    onClick={handleEmailSignup}
                                    sx={{ mt: 2 }}
                                >
                                    Create Account
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

export default EmailPasswordFirebaseSignup;
