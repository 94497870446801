import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography, List, ListItem, ListItemText, Grid, Paper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import { useLocation } from "react-router-dom";
import { formatEventDateSetter, getDateAsPerFormat } from "../../_helpers/reusablefunctions/dateSetter";
import ReusableComponent from "../reusableComponents/reusableInputComponents";
import ReusableMultiComponent from "../reusableComponents/ReusableMultiComponent";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import { useHistory } from "react-router-dom";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import { regexValidator } from "../../_helpers/reusablefunctions/regexValidator";
import { useSelector } from "react-redux";
import useSnackDispatcher from "../../_helpers/reusablefunctions/snackBarComponent";
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import noImage from "../../img/no_image.png";
import ReusableCardView from "../reusableComponents/ReusableCardView";

const ViewEventManagement = () => {
    const history = useHistory();
    const userData = useSelector((state) => state.userData.userData);
    const { successSnack, errorSnack } = useSnackDispatcher();
    const location = useLocation()?.state;
    const locationEventDetail = location?.eventData
    const [eventData, setEventData] = useState([]);
    const [eventDeliverables, setEventDeliverables] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loader, setLoader] = useState(false);
    const [proposed_amount, setproposed_amount] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState('');
    const [eventDetail, setEventDetail] = useState();

    function eventviewcall() {
        ApiService.get('brand_event_view', {}, locationEventDetail?.event?.event_id || locationEventDetail?.id).then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setEventDetail(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })
    }

    useEffect(() => {
        if (location?.page == 'submission') {
            ApiService.get('brand_event_submission_view', { id: locationEventDetail?.id }).then((res) => {
                if (res?.status == 200 || res?.status == 201) {
                    let response = res?.data?.data
                    setproposed_amount(response[0]?.proposed_amount)
                    setDescription(response[0]?.description)
                    setSelectedOptions(response[0]?.brand_deliverables)
                }
            }).catch(error => {
                console.log("event:", error)
            })
            eventviewcall()
        } else {
            eventviewcall()
        }
    }, [location])

    // ---------------------

    useEffect(() => {
        ApiService.get('deliverables').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setEventDeliverables(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })
    }, [])

    // --------------- event parse -----------

    const parseEventHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");

        const eventData = [];
        const paragraphs = doc.querySelectorAll("p"); // Get all <p> (day headers)

        paragraphs.forEach((p, index) => {
            const dayTitle = p.textContent.trim();
            const ul = p.nextElementSibling; // Find the next <ul> element

            if (ul && ul.tagName === "UL") {
                const events = Array.from(ul.querySelectorAll("li")).map((li) => {
                    const match = li.textContent.match(/^(.*?): (.*?) - (.*?) \| (.*?)$/);
                    if (match) {
                        return {
                            title: match[1].trim(),
                            startTime: match[2].trim(),
                            endTime: match[3].trim(),
                            location: match[4].trim(),
                        };
                    }
                    return null;
                }).filter(Boolean); // Remove any null values

                eventData.push({ day: dayTitle, events });
            }
        });

        return eventData;
    };

    useEffect(() => {
        const parsedData = parseEventHTML(eventDetail?.EventSchedule);
        setEventData(parsedData);
    }, []);

    const backClickHistory = () => {
        history.push('/event')
    }

    // ----------------- form brand details submit ---------

    function validationInputs() {
        let errors = {};
        let isValid = true;

        if (!proposed_amount) {
            errors.proposed_amount = "Please enter proposed amount and minimum amount INR 1,000";
            isValid = false;
        }
        if (description.trim() === '') {
            errors.description = "Fill description and minimum 10 characters required.";
            isValid = false;
        }

        setErrors(errors); // Store errors in state
        return isValid;
    }

    function submitBrandDetail(e) {
        e.preventDefault();
        let isValid = validationInputs()
        let postData = new FormData()
        if (isValid) {
            setLoader(true)
            const data = selectedOptions.map(delivey => delivey.id)
            postData.append('description', description)
            postData.append('brand_deliverables', data.join(','))
            postData.append('description', description)
            postData.append('proposed_amount', proposed_amount)
            postData.append('event', eventDetail?.id)
            postData.append('user', userData?.id)

            ApiService.post('brand_event', postData).then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setLoader(false);
                    successSnack(formSnackbar?.profileSuccess?.successBrandMessage)
                    history.push('/event')
                }
            }).catch(error => {
                setLoader(false);
                errorSnack(formSnackbar.errors.errorMsg);
                console.log(error)
            })
        }
    }

    return (
        <Box p={4}>
            <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={`Apply for ${eventDetail?.EventName}`} />
            {/* --------------- banner ------------ */}

            <Box>
                <img src={eventDetail?.Featured_Banner || noImage} alt={eventDetail?.EventName} className="image-banner" />
            </Box>

            {/* -------- about event ---------------- */}
            <ReusableCardView title="Event details" Component={
                <Box p={3}>
                    <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: 1 }}>
                        <Box display="flex" alignItems="flex-start" flexDirection={'column'} gap={2}>
                            <Box display="flex" alignItems="center">
                                <LocationOnIcon sx={{ color: "red", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ fontSize: '14px', color: '#454545' }}>
                                    {eventDetail?.Location}
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <EventIcon sx={{ color: "red", marginRight: 1 }} />
                                <Typography variant="body1" sx={{ fontSize: '14px', color: '#454545' }}>
                                    {eventDetail?.EventFromDate && formatEventDateSetter(eventDetail?.EventFromDate, eventDetail?.EventFromTime, eventDetail?.EventToDate, eventDetail?.EventToTime)}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant="body2" sx={{ marginLeft: "auto", color: "gray" }}>
                            Posted On {getDateAsPerFormat(eventDetail?.updated_at, "DD Mon, YYYY")}
                        </Typography>
                    </Box>
                    {/* Event Description */}
                    <Typography dangerouslySetInnerHTML={{ __html: eventDetail?.AbouttheEvent }}
                        variant="body2" sx={{ marginBottom: 2 }} />
                    {/* Why Attend Engifest? */}
                    {/* <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: 2 }}>
                        Why Attend {eventDetail?.EventName}?
                    </Typography> */}
                    {/* <List>
                     <ListItem>
                         <ListItemText primary="1. Live performances by renowned artists." />
                     </ListItem>
                    
                 </List> */}
                </Box>
            } />


            {/* --------- special attraction */}

            {eventDetail?.special_attractions?.length ?
                <ReusableCardView title=" Special attraction" Component={
                    <Box p={3}>
                        {eventDetail?.special_attractions.map((section, index) => (
                            <Box key={index} sx={{ marginBottom: 3 }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1, fontSize: '16px', color: '#292929' }}>
                                    {index + 1}. {section?.SACategory}
                                </Typography>

                                <Grid container spacing={2}>
                                    {section?.data.map((event, idx) => (
                                        <Grid item xs={12} sm={6} md={4} key={idx}>
                                            <Box>
                                                <CardMedia
                                                    component="img"
                                                    height="160"
                                                    image={event?.UploadImage || noImage}
                                                    alt={event?.Title}
                                                    sx={{ width: '231px', borderRadius: '10px' }}
                                                />
                                                <Box >
                                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: '14px', color: '#1A1A1A' }}>
                                                        {event?.Title}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "13px", color: "#7A7A7A", marginBottom: 1 }}>
                                                        {event?.Location}
                                                    </Typography>
                                                    <List disablePadding>
                                                        <ListItem disableGutters sx={{ padding: 0 }}>
                                                            <ListItemText primary={event?.ArtistName} sx={{ '& span': { fontSize: "14px", color: "#7A7A7A" } }} />
                                                        </ListItem>
                                                        <ListItem disableGutters sx={{ padding: 0 }}>
                                                            <ListItemText primary={event?.FromCompany} sx={{ '& span': { fontSize: "14px", color: "#7A7A7A" } }} />
                                                        </ListItem>
                                                    </List>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                }
                /> : null}



            {/* --------------- event schedule ----------- */}
            {eventData?.length ?
                <ReusableCardView title="Event schedule" Component={
                    <Box p={3}>
                        {/* Event Days Section */}
                        <Grid container spacing={2}>
                            {eventData.map((day, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <Box elevation={2} sx={{ padding: 2, borderRadius: 2, backgroundColor: '#FCFCFC' }}>
                                        <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1, color: '#292929', fontSize: '16px' }}>
                                            {day.day}
                                        </Typography>
                                        <List>
                                            {day.events.map((event, idx) => (
                                                <ListItem key={idx} disableGutters sx={{ paddingBottom: "4px" }}>
                                                    <ListItemText
                                                        primary={<Typography sx={{ fontWeight: "bold" }}>{event.title}</Typography>}
                                                        secondary={`${event.startTime} - ${event.endTime} | ${event.location}`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                } /> : null}

            {/* -------------- event images ----------- */}
            {eventDetail?.event_images?.length ?
                <ReusableCardView title="Events" Component={
                    <Box p={3}>
                        {eventDetail?.event_images.map((section, index) => (
                            <Box key={index} sx={{ marginBottom: 3 }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1, fontSize: '16px', color: '#292929' }}>
                                    {index + 1}. {section?.EventImageTitle}
                                </Typography>

                                <Box>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={section?.EventUploadImage || noImage}
                                        alt={section?.EventImageTitle}
                                        sx={{ width: '231px', borderRadius: '10px' }}
                                    />
                                </Box>
                            </Box>
                        ))
                        }
                    </Box >
                }
                /> : null}

            {/* ----------Brand Details form --------- */}
            <Box mt={3} mb={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={6} >
                        <Card>
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: '16px', color: '#4C4E64' }}>
                                    Brand Details
                                </Typography>
                                <Box mt={2}>
                                    <ReusableComponent
                                        type="text"
                                        label="Suggested sponsorship amount"
                                        value={'1,000'}
                                        disabledValue={true}
                                        placeholder="Suggested sponsorship amount"
                                    />
                                    <ReusableMultiComponent label="Brand Deliverable*" placeholder='E.g., "Product give away, Stall "'
                                        disabledValue={location?.page == 'submission'} optionLabel="name"
                                        options={eventDeliverables} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} >
                        <Card>
                            <CardContent>
                                <ReusableComponent
                                    type="numeric"
                                    label="Proposed amount"
                                    placeholder={'Minimum amount INR 1,000'}
                                    value={proposed_amount}
                                    onChange={setproposed_amount}
                                    error={!!errors.proposed_amount}
                                    helperText={errors.proposed_amount}
                                    disabledValue={location?.page == 'submission'}
                                />
                                <ReusableComponent
                                    type="text"
                                    label="Description"
                                    rowsCount={5}
                                    multiline={true}
                                    placeholder={'A short note....'}
                                    value={description}
                                    onChange={setDescription}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                    disabledValue={location?.page == 'submission'}
                                />
                                {location?.page == 'submission' ? null : <Box textAlign={'end'}>
                                    <ReusableButton variant="contained" classNames={'gn-actionbtn'} children={'Submit'} onClick={submitBrandDetail} loader={loader} />
                                </Box>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

        </Box >
    );
};

export default ViewEventManagement;
