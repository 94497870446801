import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import cloudword from '../img-landing/cloudword.png'

function Marketing() {

  const options = {
    rotations: 2,
    rotationAngles: [0, 0],
  };
  const size = [700, 500];


  return (
    <Container className='p-0 mobilepadding'>
      <Grid container spacing={2} sx={{ flex: 1 }} className='py80'>
        <Grid className='displayStyle1 alignitemstart flexDirectionColumn' item xs={12} md={5}>
          <Typography variant="h3" gutterBottom className='font600 fontSize40'>
            What we can help you with
          </Typography>
          <Typography sx={{ width: '90%' }} variant="subtitle1" gutterBottom className='font400 fontSize18 '>
            Tailored marketing strategies to boost your brand’s presence and drive engagement across social media platforms.
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} >
          <img src={cloudword} style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </Container>
  );
}
export default Marketing;
