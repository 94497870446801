import * as React from 'react';
import './LandingContainer.css';
import '../mediaquery.css';
import { Box, Container } from '@mui/material';
import Header from './header';
import MainBanner from './mainBanner';
import WhoWeAre from './whoWeAre';
import Marketing from './marketing';
import ChooseUs from './chooseUs';
import Footer from './footer';
import ContactUs from './contactUs';
import Campaign from './campaign';
import Counts from './counts';
import Experties from './experties';
import { useLocation } from 'react-router-dom';

function LandingContainer() {
  let location = useLocation();

  return (
    <Box className="landing-container">
      <Container className='p-0 mobilepadding'>
        <Header />
        <MainBanner />
      </Container>
      <WhoWeAre />
      <Marketing />
      <Experties />
      <Counts />
      <Campaign />
      <ChooseUs />
      <ContactUs />
      <Footer />
    </Box>
  );
}

export default LandingContainer;


