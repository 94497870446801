import { Tab, Tabs } from "@mui/material";
import React from "react";

const ReusableTabs = ({ setValue, value }) => {
    const tabList = [
        { value: 0, label: 'Details' },
        { value: 1, label: 'Analytics' },
    ]
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            sx={{
                '& .MuiTabs-indicator': {
                    backgroundColor: '#F14950',
                },
            }}
        >
            {tabList.map(d => (
                <Tab key={d.value} label={d.label} iconPosition="start" sx={{ fontWeight: 600, color: value == d.value ? '#dc3545 !important' : '#4C4E64AD' }} />
            ))}
            {/* <Tab sx={{ color: tabIndex == d.value ? '#dc3545 !important' : '#4C4E64AD' }} className="profile-list-style" value="one" label="Details" />
            <Tab className="profile-list-style" value="two" label="Analytics" /> */}
        </Tabs>
    );
};

export default ReusableTabs;
