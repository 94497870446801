import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export default function SnackbarComponent({ open, setOpen, snackMessage, severity }) {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={<Typography sx={{ fontSize: '16px' }}>{snackMessage}</Typography>}
            >
                <Alert onClose={handleClose} severity={severity}>
                    <Typography sx={{ fontSize: '16px' }}>{snackMessage}</Typography>
                </Alert>
            </Snackbar>
        </div>
    );
}
