import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import SnackbarContainer from '../Snackbar';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import BrandDetailsForm from './brandDetailsForm';
import ProfileFinal from './profileFinal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Profile(props) {
    const [type, setType] = useState("category")
    const [active, setActive] = useState("6")
    let { childPath } = useParams();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const [profileTabs, setProfileTabs] = useState([
        {
            id: 1,
            tabActiveId: "1",
            tabTitle: 'Profile',
            tabType: 'profile'
        }
    ])
    useEffect(function () {
        dispatch(fetchUserDetail());
        let currentURL = window.location.href.split('/profile?user=')[1]
        if (currentURL === undefined)
            currentURL = window.location.href.split('/?user=')[1]
        const currentTab = profileTabs.find(item => item.tabType === currentURL)
        currentTab && setActive(currentTab.tabActiveId)
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
        }

    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:1100px)');
    const tabStyle = {
        bgcolor: 'background.paper',
        borderRadius: '10px',
        margin: mediumViewport ? '4px' : '10px 0px 10px 0px',
        width: '188px'
    }
    return (
        <>
            <div className='p-4 m-auto container '>
                <h1 className='fs-2'>Profile</h1>
                <div className="d-flex main-profile-container mb-5 flex-wrap">
                    <div className="profile-tabs">
                        <Box sx={tabStyle} className='shadowCustom'>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                orientation={mediumViewport ? "vertical" : "horizontal"}
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#F14950', // Custom color
                                    },
                                }}
                            >
                                {profileTabs ? profileTabs.map(item => {
                                    return (
                                        <Tab
                                            key={item.id}
                                            component={Link}
                                            to={`?user=${item.tabType}`}
                                            label={item.tabTitle}
                                            onClick={() => { setType(item.tabType); setActive(item.tabActiveId) }}
                                            className={`align-items-start ${active == item.tabActiveId ? "profile-list-style fw-bold" : "text-secondary"}`}
                                        />
                                    )
                                }) : null}
                            </Tabs>
                        </Box>
                    </div>
                    <div className="probile-tab-content">
                        <Box className='shadowCustom' sx={{ minHeight: '350px', bgcolor: 'background.paper', borderRadius: '10px', margin: '5px 0px', padding: '20px' }}>
                            {(() => {
                                switch (window.location.search) {
                                    case '?user=general':
                                        return <BrandDetailsForm />;

                                    default:
                                        return <BrandDetailsForm />
                                }
                            })()}
                        </Box>
                    </div>
                </div>
            </div>
            <SnackbarContainer />
        </>
    );
}

export default Profile;