
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReusableCard from "../reusableComponents/ReusableCard";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import { CardSkeletonCollab } from "../../components/reusableComponents/SkeletonCard";
import NoResults from "../../components/reusableComponents/NoResults";
import { useHistory } from "react-router-dom";

const GigManagement = () => {
    const history = useHistory();
    const [gigManagement, setGigManagement] = useState([])
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    // ------------------ Event List Get API Call 

    useEffect(() => {
        setLoader(true)
        ApiService.get('gigs', { offset: page, limit: rowsPerPage }).then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setGigManagement(res?.data?.data)
            }
            setLoader(false)
        }).catch(error => {
            setLoader(false)
            console.log("event:", error)
        })
    }, [page, rowsPerPage])

    // -----------------

    function openSubmission(params) {
        history.push('/gig-management/create-gig-management')
    }

    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents heading={'Gig Posting and Management'} subHeading={'Easily post, manage, and track all your gigs in one place.'} />
                <ReusableButton children={'Create gig work'} variant="contained"
                    classNames={'gn-actionbtn'} onClick={openSubmission} />
            </Box>
            <Box mb={4}>
                <Grid container spacing={2}>
                    {loader ? (
                        ['', '', '', ''].map((d, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                <CardSkeletonCollab />
                            </Grid>
                        ))
                    ) : gigManagement.length ? (
                        gigManagement.map((data, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <ReusableCard
                                    title={data?.Title || ''}
                                    description={data?.SubTitle || ''}
                                    // date={formatEventDate(data?.EventFromDate, data?.EventToDate)}
                                    primaryAction={{ label: "" }}
                                    secondaryAction={{ label: "View", onClick: () => history.push('/gig-management/view-gig', { eventData: data }) }}
                                />
                            </Grid>
                        ))
                    ) : (
                        <NoResults />
                    )}
                </Grid>


            </Box>

        </Box>
    );
};

export default GigManagement;
