import * as React from 'react';
import Box from '@mui/material/Box';
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import logo from '../img/logo.png'
import insta from '../img/socialimg/insta.svg'
import linkedin from '../img/socialimg/linkedin.svg'
import fb from '../img/socialimg/fb.svg'
import whatsapp from '../img/socialimg/whatsapp.svg'


export default function Footer() {

    const overview = [{ name: "About Us" }, { name: "Privacy Policy" }, { name: "Terms & Conditions" },]
    const support = [{ supportname: "(+91) 96507 78566", icons: <PhoneIcon sx={{ fontSize: '2rem' }} /> }, { supportname: "Info@groovenexus.com", icons: <EmailIcon sx={{ fontSize: '2rem' }} /> }, { supportname: "189 Pratap Nagar, Mayur Vihar Phase 1 Delhi 110091", icons: <LocationOnIcon sx={{ fontSize: '2rem' }} /> },]

    return (
        <Box className='footerbgColor'>
            <Container className='p-0 mobilepadding'>
                <Box className='py80'>
                    <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <img src={logo} style={{width:'70%'}} />
                            <Typography className='font400 fontSize14'>From Beats to Fans, Make Your Music Journey Remarkable with GrooveNexus Ads Services!</Typography>
                            <Box className="displayStyle" gap={1} mt={2}>
                                <a href="https://www.facebook.com/thegroovenexus/" className=''>
                                    <img src={fb} className='socialIcons' /></a>
                                <a href="https://www.linkedin.com/company/groovenexus/mycompany/" className=''><img
                                    src={linkedin} className='socialIcons' /></a>
                                <a href="https://www.instagram.com/groovenexus?igsh=MTNyeDBrbGh2cXMzbQ==" className=''>
                                    <img src={insta} className='socialIcons' /></a>
                                <a href="https://wa.me/8126430111" className=''>
                                    <img src={whatsapp} className='socialIcons' /></a>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <Typography className='font600 fontSize18' variant="h6">
                                Overview
                            </Typography>
                            <List>
                                {overview.map((d, i) => (
                                    <ListItem key={i} sx={{ paddingLeft: '0px' }}>
                                        <ListItemText
                                            primary={<Typography className='font400 fontSize14'>{d.name}</Typography>}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography className='font600 fontSize18' variant="h6" >
                                Support
                            </Typography>
                            <List>
                                {support.map((d, i) => (
                                    <ListItem key={i} sx={{ paddingLeft: '0px' }}>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            {d.icons}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography className='font400 fontSize14'>{d.supportname}</Typography>}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13992.344198394303!2d77.30652676651174!3d28.603845459151654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce418b1d963ed%3A0x96c8744dc14b2c52!2s189%2C%20Pratap%20Nagar%2C%20Mayur%20Vihar%20Phase%201%2C%20New%20Delhi%2C%20Delhi%20110091!5e0!3m2!1sen!2sin!4v1689514350784!5m2!1sen!2sin"
                                width="100%" height="171"
                                style={{ borderRadius: '40px' }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box sx={{ backgroundColor: '#000', padding: '15px', textAlign: 'center' }}>
                <Typography className='fontSize14' sx={{ color: '#fff' }}>Copyright @ 2024. GrooveNexus</Typography>
            </Box>
        </Box>
    );
}
