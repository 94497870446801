import React, { useState } from 'react';
import logoGntunes from "../../img/logoGntunes.svg";
import Box from "@mui/material/Box";
import { useDispatch } from 'react-redux'
import Modal from "@mui/material/Modal";
import { TextField } from '@mui/material';
import backgroundImage from '../../img/bg_image_texture4.png'
import Footer from '../footer';
import { setLoader } from '../../redux';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { useHistory } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 3
};
function ForgotPasswordFirebase(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)
    const LogoApp =  logoGntunes

    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            data.append('email', email);
            setEmailError("")
        } else {
            setEmailError(
                <label className="text-danger mt-1" style={{ marginLeft: '10px' }}>Please enter valid Email</label>
            )
        }
        if (email.match(mailformat)) {
            dispatch(setLoader(true))
            try {
                dispatch(setLoader(false))
                await sendPasswordResetEmail(auth, email);
                handleOpen()
                setTimeout(() => history.push("/login"), 2000);
                console.log("Password reset email sent! Check your inbox.");
            } catch (error) {
                dispatch(setLoader(false))
                console.error("Error sending reset email:", error.message);
            }
        }
    }

    return (
        <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand p-3" href="https://brands.groovenexus.com/"><img src={LogoApp} /></a>
                    </div>
                </nav>
                <div style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
                    <div className="login-form  mt-4 mb-4 p-4 border rounded shadowCustom bg-white">
                        <form action="" method="" className="row g-3 mt-2">
                            <h6 className="  text-secondary mx-2">
                                Enter your registered email address to change your GN Tunes account password.
                            </h6>
                            <div >
                                <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    size='small'
                                    className="w-100"
                                    placeholder="Email"
                                    onChange={e => { setEmail(e.target.value); setEmailError("") }}
                                />
                            </div>
                            {emailError}
                            <div>
                            </div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className='text-secondary' >
                                    {/* <h6>Mail has been sent to your registered email</h6> */}
                                    <h6>Password reset link has been send to your registered email address.</h6>

                                </Box>
                            </Modal>
                            <div className="col-md-12">
                                <button type="submit" className="gn-actionbtn float-end w-40 py-2" onClick={onClickHandler}>Send Password Reset Link</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPasswordFirebase;