import React, { useEffect, useState } from "react";
import Tablecomponent from "../reusableComponents/Tablecomponent";
import { Box, Button, Menu, MenuItem, Paper } from "@mui/material";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import { useHistory } from "react-router-dom";
import ApiService from "../../_services/ApiService";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const OfferTableView = () => {
  const history = useHistory();
  const userData = useSelector((state) => state.userData.userData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [offerApplicantList, setOfferApplicantList] = useState([]);
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  useEffect(() => {
    if (userData) {
    //   ApiService.get('brand_event_submission', { offset: page, limit: rowsPerPage, user: userData?.id }).then((res) => {
    //     let response = res?.data?.results
    //     if (res.status == 200 || res.status == 201) {
    //       setOfferApplicantList(response)
    //     }
    //   }).catch(error => {
    //     console.log('submission: error', error)
    //   })
    }
  }, [userData, page, rowsPerPage])

  const rows = offerApplicantList || [];

  const columns = [
    {
      id: 'event', label: 'Applicants', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer'>{row?.event?.event_name}</Box>
        )
      }
    },
    {
      id: 'location', label: 'Offers', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer'>{row?.event?.location}</Box>
        )
      }
    },
    { id: 'suggested_amount', numeric: true, field: "suggested_amount", disablePadding: false, label: 'Start date', },
    { id: 'proposed_amount', numeric: true, field: "proposed_amount", disablePadding: false, label: 'End date', },
    { id: 'description', numeric: true, field: "description", disablePadding: false, label: 'Status', },
  
  ];

  const backClickHistory = () => {
    history.push('/event')
  }
  return (
    <Box pb={4}>
      <Paper>
        <Tablecomponent
          columns={columns}
          rowsPerPage={rowsPerPage}
          page={page}
          data={rows}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default OfferTableView;
