import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ReusableComponent from "../reusableComponents/reusableInputComponents";
import { useHistory } from "react-router-dom";
import RichTextEditor from "../reusableComponents/richTextEditior";
import ReusableCardView from "../reusableComponents/ReusableCardView";
import useSnackDispatcher from "../../_helpers/reusablefunctions/snackBarComponent";
import ApiService from "../../_services/ApiService";
import { regexValidator } from "../../_helpers/reusablefunctions/regexValidator";
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import ReusableMultiComponent from "../reusableComponents/ReusableMultiComponent";
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default function CreateGigManagement(params) {
    const { successSnack, errorSnack } = useSnackDispatcher();
    const history = useHistory();
    const userData = useSelector((state) => state.userData.userData);
    const [GigWorkType, setGigWorkType] = useState([]);
    const [GigWorkTypeSelection, setGigWorkTypeSelection] = useState([]);
    const [Requirements, setRequirements] = useState("");
    const [AboutGigWork, setAboutGigWork] = useState("");
    const [RewardAmount, setRewardAmount] = useState("");
    const [FAQ, setFAQ] = useState("");
    const [Completethetask, setCompletethetask] = useState("");
    const [Title, setTitle] = useState("");
    const [DeadLineDate, setDeadLineDate] = useState("");
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        ApiService.get('gigsType').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setGigWorkTypeSelection(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })
    }, [])



    // --------------- validation handler ----------

    function validationInputs() {
        let errors = {};
        let isValid = true;

        if (Title.trim() === '' || !regexValidator(Title, 'textSpaceEndStart')) {
            errors.Title = "Gig title is required.";
            isValid = false;
        }
        if (AboutGigWork.trim() === '') {
            errors.AboutGigWork = "Gig description is required and start & end space is not allowed";
            isValid = false;
        }
        if (RewardAmount.trim() === '') {
            errors.RewardAmount = "Reward amount is required.";
            isValid = false;
        }

        setErrors(errors); // Store errors in state
        return isValid;
    }

    // ------------- submit handler ---------

    function submitProfile(e) {
        e.preventDefault();
        let isValid = validationInputs()
        if (isValid) {
            const gigWorkType = GigWorkType.map(type => type.id)

            let payload = {
                'Title': Title,
                'Requirements': Requirements,
                'AboutGigWork': AboutGigWork,
                'RewardAmount': RewardAmount,
                'FAQ': FAQ,
                'GigWorkType': gigWorkType,
                'Completethetask': Completethetask,
                "DeadLineDate": DeadLineDate,
                'BrandId': userData?.id
            }

            setLoader(true);
            ApiService.post('gigscreate', payload).then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setLoader(false);
                    successSnack(formSnackbar?.profileSuccess?.successGigMessage)
                    history.push('/gig-management')
                } else {
                    const errorData = res?.data || {};
                    Object.keys(errorData).forEach(field => {
                        let messages = errorData[field];

                        if (Array.isArray(messages)) {
                            messages = messages.join(', ');
                        } else if (typeof messages === "object" && messages !== null) {
                            messages = JSON.stringify(messages);
                        }

                        errorSnack(`${field}: ${messages}`);
                    });
                }
            }).catch(error => {
                setLoader(false);
                errorSnack(formSnackbar.errors.errorMsg);
                console.log(error)
            })
        }
    }


    function backClickHistory(params) {
        history.push('/gig-management')
    }

    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={'Create new gig'} subHeading={'Effortlessly Create, Manage, and Track Your Gig Opportunities'} />
                <Box>
                    {/* <ReusableButton variant="outlined" classNames={'gn-actionbtn-outline gn-actionbtn-color '} children="Save Draft" sx={{ color: '#6D788D', marginRight: '10px' }} /> */}
                    <ReusableButton loader={loader} classNames={'gn-actionbtn'}
                        variant="outlined" children="Publish gig"
                        sx={{ color: '#6D788D' }}
                        onClick={submitProfile} />
                </Box>
            </Box>
            <Grid container spacing={2} >
                {/* Left Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Basic" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableComponent
                                type="text"
                                label="Gig title"
                                value={Title}
                                onChange={setTitle}
                                error={!!errors.Title}
                                helperText={errors.Title}
                                placeholder={'Gig title'} />
                            <ReusableComponent type="text"
                                label="Gig description"
                                rowsCount={5}
                                multiline={true}
                                value={AboutGigWork}
                                onChange={setAboutGigWork}
                                error={!!errors.AboutGigWork}
                                helperText={errors.AboutGigWork}
                                placeholder={'Detailed explanation of the gig'}
                            />
                            <Box display={'flex'} alignItems={'baseline'} gap={3} >
                                <ReusableComponent type="numeric"
                                    label="Gig amount"
                                    value={RewardAmount}
                                    onChange={setRewardAmount}
                                    error={!!errors.RewardAmount}
                                    helperText={errors.RewardAmount}
                                    placeholder={'INR'} />

                                <FormControl>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Deadline"
                                            inputFormat="DD/MM/YYYY"
                                            value={DeadLineDate}
                                            name="DeadLineDate"
                                            minDate={new Date()}
                                            onChange={(date) => setDeadLineDate(dayjs(date).format("YYYY-MM-DD"))}
                                            renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                        </Box>
                    } />
                </Grid>

                {/* Right Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Eligible users" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableMultiComponent label={'Target audience'}
                                options={GigWorkTypeSelection}
                                selectedOptions={GigWorkType}
                                setSelectedOptions={setGigWorkType}
                                optionLabel="TypeName"
                            />
                        </Box>
                    } />
                </Grid>
                {/* ---------------------Task List --------------- */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="Task list" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <RichTextEditor
                                value={Completethetask}
                                onChange={setCompletethetask}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>

                {/* ----------------- Requirement ------------------ */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="Requirement" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <RichTextEditor
                                value={Requirements}
                                onChange={setRequirements}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>

                {/* ----------------- FAQ ------------------ */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="FAQ" Component={
                        <Box p={3}>
                            <RichTextEditor
                                value={FAQ}
                                onChange={setFAQ}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>
            </Grid>
        </Box>
    )

}