import React, { useState } from "react";
import { Autocomplete, TextField, Chip, Stack } from "@mui/material";

const ReusableMultiComponent = ({ 
  label, 
  placeholder, 
  options, 
  selectedOptions, 
  setSelectedOptions, 
  disabledValue, 
  optionLabel = "name" // Default key to use for labels
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Stack spacing={1}>
      {/* Input Field */}
      <Autocomplete
        freeSolo
        options={options.filter((option) => 
          !selectedOptions.some(selected => selected[optionLabel] === option[optionLabel])
        )} // Hide already selected values
        inputValue={inputValue}
        onInputChange={(event, newValue) => setInputValue(newValue)}
        getOptionLabel={(option) => option[optionLabel] || ""}
        onChange={(event, newValue) => {
          if (newValue && !selectedOptions.some(selected => selected[optionLabel] === newValue[optionLabel])) {
            setSelectedOptions([...selectedOptions, newValue]); // Add new selection
            setInputValue(""); // Clear input field after selection
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} />
        )}
        disabled={disabledValue}
        className="mb-2"
      />

      {/* Chips Displayed Below */}
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {selectedOptions?.map((option, index) => (
          <Chip
            key={option[optionLabel]}
            label={option[optionLabel]}
            onDelete={() => {
              setSelectedOptions(selectedOptions.filter(item => item[optionLabel] !== option[optionLabel]));
              setInputValue(""); // Clear input field after deletion
            }}
            className="gn-actionbtn"
            disabled={disabledValue}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ReusableMultiComponent;
