import { Box } from '@mui/material'
import React from 'react'

const NoResults = ({eventNoData}) => {
  return (
    <Box className="d-flex justify-content-center align-items-center w-100" 
    sx={{color:'#4C4E64DE', height:'100px'}}>
        <h5>{eventNoData}</h5>
    </Box>
  )
}

export default NoResults