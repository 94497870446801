import React from "react";
import { Card, CardContent, CardMedia, Typography, Button, Stack, Box, Chip } from "@mui/material";
import ReusableButton from "./reusableButtonComponents";
import PinDropIcon from '@mui/icons-material/PinDrop';
import WalletIcon from '@mui/icons-material/Wallet';

const ReusableCard = ({
  image,
  title,
  description,
  date,
  applicants,
  audience,
  sponsor,
  status,
  primaryAction,
  secondaryAction,
  amount,
  location,
  internshipdate,
  interImage,
  OfferButtonLabel
}) => {
  return (
    <Card className="shadowCustom" sx={{ borderRadius: 3, display: "flex", flexDirection: "column", height: '100%' }}>
      {/* Image Section (Optional) */}
      {image && (
        <CardMedia
          component="img"
          height="160"
          image={image}
          alt={title}
        // sx={{ borderRadius: 2 }}
        />
      )}

      <CardContent sx={{ flexGrow: 1 }}>
        {/* Title & Status */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold" sx={{ fontSize: '16px' }}>
            {title}
          </Typography>
          {status && <Chip label={status} color="success" size="small" sx={{ backgroundColor: "#e5faeb" }} />}
          {interImage && (
            <CardMedia
              component="img"
              height="80"
              image={interImage}
              alt={title}
              sx={{ width: 100, borderRadius: '10px' }}
            />
          )}
        </Stack>

        {/* Description */}
        {description && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {description}
          </Typography>
        )}

        {OfferButtonLabel && (
          <Typography variant="body2" color="text.secondary" sx={{
            mt: 0.5, backgroundColor: '#BEF4D5',
            padding: '5px',
            borderRadius: '10px'
          }}>
            {OfferButtonLabel}
          </Typography>
        )}

        {/* Extra Details */}
        {date && (
          <Typography variant="body2" fontWeight="bold" sx={{ mt: 1, color: '#7A7A7A', fontSize: '14px' }}>
            Date: <span style={{ fontWeight: "normal" }}>{date}</span>
          </Typography>
        )}
        {audience && (
          <Typography variant="body2" fontWeight="bold">
            Audience: <span style={{ fontWeight: "normal" }}>{audience}</span>
          </Typography>
        )}
        {sponsor && (
          <Typography variant="body2" fontWeight="bold">
            Supporting Sponsor: <span style={{ fontWeight: "normal" }}>{sponsor}</span>
          </Typography>
        )}
        {applicants && (
          <Typography variant="body2" color="text.secondary">
            {applicants} applicants
          </Typography>
        )}

        {/* ------------------ intership data -------- */}
        {amount && (
          <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
            <WalletIcon fontSize='small' /> {amount + '/month'}
          </Typography>
        )}
        {location && (
          <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
            <PinDropIcon fontSize='small' /> {location}
          </Typography>
        )}
        {internshipdate && (
          <Typography variant="body2" fontWeight="bold" sx={{ mt: 1, color: '#7A7A7A', fontSize: '14px' }}>
            <span style={{ fontWeight: "normal" }}>{internshipdate}</span>
          </Typography>
        )}

      </CardContent>

      {/* Action Buttons */}
      <Stack direction="row" justifyContent="space-between" sx={{ px: 2, pb: 2 }}>
        {primaryAction && (
          <ReusableButton variant="text"
            classNames={'gn-actionbtn-color'}
            onClick={primaryAction.onClick}
            children={primaryAction.label}
            sx={{ color: '#6D788D' }} />
        )}
        {secondaryAction && (
          <ReusableButton variant="contained"
            classNames={'gn-actionbtn'}
            children={secondaryAction.label}
            onClick={secondaryAction.onClick} />
        )}
      </Stack>
    </Card>
  );
};

export default ReusableCard;
