import React, { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup, Grid, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ReusableComponent from "../reusableComponents/reusableInputComponents";
import { useHistory } from "react-router-dom";
import RichTextEditor from "../reusableComponents/richTextEditior";
import ReusableCardView from "../reusableComponents/ReusableCardView";
import useSnackDispatcher from "../../_helpers/reusablefunctions/snackBarComponent";
import ApiService from "../../_services/ApiService";
import { regexValidator } from "../../_helpers/reusablefunctions/regexValidator";
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import ReusableMultiComponent from "../reusableComponents/ReusableMultiComponent";
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import ReusableCheckboxGroup from "../reusableComponents/reusableCheckboxGroup ";
import { SCHEDULE_CHOICES, STIPEND_CHOICES, TYPESEMPLOYMENT_CHOICES, TYPESWORKPLACE_CHOICES } from "./listJsonInternship";
import ReusableRadioGroup from "../reusableComponents/reusableRadioGroup ";

export default function CreateInternshipManagement(params) {
    const { successSnack, errorSnack } = useSnackDispatcher();
    const history = useHistory();
    const userData = useSelector((state) => state.userData.userData);
    const [Title, setTitle] = useState("");
    const [SocialLink, setSocialLink] = useState("");
    const [JobLocation, setJobLocation] = useState("");
    const [TotalOpening, setTotalOpening] = useState("");
    const [TotalExperience, setTotalExperience] = useState("");

    const [InternshipList, setInternshipList] = useState([]);
    const [InternshipListSelection, setInternshipListSelection] = useState([]);

    const [SkillsList, setSkillsList] = useState([]);
    const [SkillsListSelection, setSkillsListeSelection] = useState([]);

    const [BenefitsList, setBenefitsList] = useState([]);
    const [BenefitsListSelection, setBenefitsListSelection] = useState([]);
    const [StipendAmount, setStipendAmount] = useState("");
    const [Stipend, setStipend] = useState("");
    const [Is_Negotiable, setIs_Negotiable] = useState("");
    const [WorkingSchedule, setWorkingSchedule] = useState([]);
    const [TypesEmployment, setTypesEmployment] = useState([]);
    // const [RequirementsList, setRequirementsList] = useState([]);
    const [RequirementsList, setRequirementsList] = useState([
        {
            "id": 1,
            "Name": "Youth",
            "Value": 'Enable_Student'
        },
        {
            "id": 2,
            "Name": "Influencers",
            "Value": 'Enable_Creator'
        },
        {
            "id": 3,
            "Name": "Music Artists",
            "Value": 'Enable_Artist'
        },
        {
            "id": 4,
            "Name": "All Users"
        }
    ]);
    const [RequirementsListSelection, setRequirementsListSelection] = useState([]);

    const [TypesWorkplace, setTypesWorkplace] = useState("remote");

    const [FAQ, setFAQ] = useState("");
    const [JobDescription, setJobDescription] = useState("");
    const [DeadLineDate, setDeadLineDate] = useState("");
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);

    const handleWorkModeChange = (event, option) => {
        setTypesWorkplace(event.target.value);
    };

    // Change handler for stipend
    const handleStipendChange = (event, option) => {
        setStipend(event.target.value);
    };

    const handlWorkingScheduleChange = (event, option) => {
        setWorkingSchedule(event.target.value);
    };

    // Change handler for stipend
    const handleTypesEmploymentChange = (event, option) => {
        setTypesEmployment(event.target.value);
    };



    useEffect(() => {
        ApiService.get('intershipType').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setInternshipListSelection(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })

        // --------------intershipSkills-------------

        ApiService.get('intershipSkills').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setSkillsListeSelection(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })

        // -----------------------

        ApiService.get('intershipBenefit').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setBenefitsListSelection(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })

        // ApiService.get('offers_requirements').then((res) => {
        //     if (res?.status == 200 || res?.status == 201) {
        //         setRequirementsList(res?.data?.data)
        //     }
        // }).catch(error => {
        //     console.log("event:", error)
        // })

    }, [])



    // --------------- validation handler ----------

    function validationInputs() {
        let errors = {};
        let isValid = true;

        if (Title.trim() === '' || !regexValidator(Title, 'textSpaceEndStart')) {
            errors.Title = "Internship title is required.";
            isValid = false;
        }
        if (SocialLink.trim() === '' || !regexValidator(SocialLink, 'URL')) {
            errors.SocialLink = "Social link is required";
            isValid = false;
        }
        if (JobLocation.trim() === '') {
            errors.JobLocation = "Job location is required.";
            isValid = false;
        }
        if (TotalOpening.trim() === '') {
            errors.TotalOpening = "Total Opening is required.";
            isValid = false;
        }
        if (TotalExperience.trim() === '') {
            errors.TotalExperience = "Total Experience is required.";
            isValid = false;
        }

        setErrors(errors); // Store errors in state
        return isValid;
    }

    // ------------- submit handler ---------

    function submitProfile(e) {
        e.preventDefault();
        let isValid = validationInputs()
        if (isValid) {
            const benefitsList = BenefitsList.map(type => type.id)
            // const requirementsList = RequirementsListSelection.map(type => type.id)
         
            const internshipList = InternshipList.map(type => type.id)
            const skillsList = SkillsList.map(type => type.id)

            let payload = {
                "Title": Title,
                "SocialLink": SocialLink,
                "InternshipList": internshipList,
                "JobLocation": JobLocation,
                "TotalOpening": TotalOpening,
                "TotalExperience": TotalExperience,
                "Stipend": Stipend,
                "StipendAmount": StipendAmount,
                "Is_Negotiable": Is_Negotiable ? 'yes' : 'no',
                "WorkingSchedule": WorkingSchedule,
                "TypesEmployment": TypesEmployment,
                "TypesWorkplace": TypesWorkplace,
                "DeadLineDate": DeadLineDate,
                // "RequirementsList": requirementsList,
                // ...RequirementsListSelection
                //     .filter(delivey => delivey?.Value)
                //     .reduce((acc, delivey) => ({ ...acc, [delivey.Value]: true }), {}),
                "SkillsList": skillsList,
                "BenefitsList": benefitsList,
                "JobDescription": JobDescription,
                "FAQ": FAQ,
                'BrandId': userData?.id
            }
            setLoader(true);
            ApiService.post('Internshipscreate', payload).then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setLoader(false);
                    successSnack(formSnackbar?.profileSuccess?.successInternshipMessage)
                    history.push('/Internship')
                } else {
                    const errorData = res?.data || {};
                    Object.keys(errorData).forEach(field => {
                        let messages = errorData[field];

                        if (Array.isArray(messages)) {
                            messages = messages.join(', ');
                        } else if (typeof messages === "object" && messages !== null) {
                            messages = JSON.stringify(messages);
                        }

                        errorSnack(`${field}: ${messages}`);
                    });
                }
            }).catch(error => {
                setLoader(false);
                errorSnack(formSnackbar.errors.errorMsg);
                console.log(error)
            })
        }
    }


    function backClickHistory(params) {
        history.push('/Internship')
    }

    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={'Internship Application Form'} subHeading={''} />
                <Box>
                    {/* <ReusableButton variant="outlined" classNames={'gn-actionbtn-outline gn-actionbtn-color '} children="Save Draft" sx={{ color: '#6D788D', marginRight: '10px' }} /> */}
                    <ReusableButton loader={loader} classNames={'gn-actionbtn'}
                        variant="outlined" children="Publish internship"
                        sx={{ color: '#6D788D' }}
                        onClick={submitProfile} />
                </Box>
            </Box>
            <Grid container spacing={2} >
                {/* Left Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Basic" Component={
                        <Box px={3} pt={3}>
                            <ReusableComponent
                                type="text"
                                label="Internship title"
                                value={Title}
                                onChange={setTitle}
                                error={!!errors.Title}
                                helperText={errors.Title}
                                placeholder={'Internship title'} />

                            <ReusableComponent
                                type="text"
                                label="Social links"
                                value={SocialLink}
                                onChange={setSocialLink}
                                error={!!errors.SocialLink}
                                helperText={errors.SocialLink}
                                placeholder="Social link"
                            />
                        </Box>
                    } />

                    <ReusableCardView title="Internship criteria  " Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableComponent
                                type="text"
                                label="Job location"
                                value={JobLocation}
                                onChange={setJobLocation}
                                error={!!errors.JobLocation}
                                helperText={errors.JobLocation}
                                placeholder={'Job location'} />

                            <ReusableComponent
                                type="numeric"
                                label="No. of opening*"
                                value={TotalOpening}
                                onChange={setTotalOpening}
                                error={!!errors.TotalOpening}
                                helperText={errors.TotalOpening}
                                placeholder={'Total opening'} />

                            <ReusableComponent
                                type="text"
                                label="Experience"
                                value={TotalExperience}
                                onChange={setTotalExperience}
                                error={!!errors.TotalExperience}
                                helperText={errors.TotalExperience}
                                placeholder="Experience"
                            />


                            <ReusableRadioGroup
                                label="Stipend"
                                options={STIPEND_CHOICES}
                                value={Stipend}
                                onChange={handleStipendChange}
                            />

                            <ReusableComponent type="numeric"
                                label="Amount"
                                value={StipendAmount}
                                onChange={setStipendAmount}
                                error={!!errors.StipendAmount}
                                helperText={errors.StipendAmount}
                                placeholder={'e.g.,1000'} />

                            {/* <Stack direction="row" spacing={1} mt={1} sx={{ alignItems: 'center' }}>
                                <AntSwitch checked={Is_Negotiable} onChange={(e) => setIs_Negotiable(e.target.checked)}
                                    inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Salary is negotiable</Typography> */}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={Is_Negotiable} onChange={(e) => setIs_Negotiable(e.target.checked)} name="Salary is negotiable" />
                                    }
                                    label="Salary is negotiable"
                                />
                            </FormGroup>
                            {/* </Stack> */}

                            <ReusableRadioGroup
                                label="Working schedule"
                                options={SCHEDULE_CHOICES}
                                value={WorkingSchedule}
                                onChange={handlWorkingScheduleChange}
                            // optionLabel="Name"
                            />

                            <ReusableRadioGroup
                                label="Employment type"
                                options={TYPESEMPLOYMENT_CHOICES}
                                value={TypesEmployment}
                                onChange={handleTypesEmploymentChange}
                            // optionLabel="Name"
                            />

                            <ReusableRadioGroup
                                label="Work Mode"
                                options={TYPESWORKPLACE_CHOICES}
                                value={TypesWorkplace}
                                onChange={handleWorkModeChange}
                            />


                            {/* <ReusableCheckboxGroup
                                title="Requirements"
                                options={RequirementsList}
                                selectedOptions={RequirementsListSelection}
                                setSelectedOptions={setRequirementsListSelection}
                                optionLabel="Name"
                            /> */}

                            <Box classNames='d-flex align-items-baseline gap-3'>
                                <FormControl>
                                    <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#4C4E64', fontSize: '14px' }}>Expired</Typography>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Dead line date"
                                            inputFormat="DD/MM/YYYY"
                                            value={DeadLineDate}
                                            name="DeadLineDate"
                                            minDate={dayjs()}
                                            onChange={(date) => setDeadLineDate(dayjs(date).format("YYYY-MM-DD"))}
                                            renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                        </Box>
                    } />
                </Grid>

                {/* Right Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Department " Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableMultiComponent label={'Category'}
                                options={InternshipListSelection}
                                selectedOptions={InternshipList}
                                setSelectedOptions={setInternshipList}
                                optionLabel="TypeName"
                            />
                        </Box>
                    } />

                    <ReusableCardView title="Add skills " Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableMultiComponent label={'Key skills'}
                                options={SkillsListSelection}
                                selectedOptions={SkillsList}
                                setSelectedOptions={setSkillsList}
                                optionLabel="Name"
                            />
                        </Box>
                    } />

                    <ReusableCardView title="Benefits " Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableMultiComponent label={'Benefits'}
                                options={BenefitsListSelection}
                                selectedOptions={BenefitsList}
                                setSelectedOptions={setBenefitsList}
                                optionLabel="Name"
                            />
                        </Box>
                    } />

                </Grid>


                {/* ---------------------Job Description--------------- */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="Job description" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <RichTextEditor
                                value={JobDescription}
                                onChange={setJobDescription}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>

                {/* ----------------- FAQ ------------------ */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="FAQ" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <RichTextEditor
                                value={FAQ}
                                onChange={setFAQ}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>
            </Grid>
        </Box>
    )

}