import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../img-landing/logo.png'
import { Box, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header(props) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const mdScreen = useMediaQuery('(max-width:768px)');
  const smScreen = useMediaQuery('(max-width:600px)');


  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar sx={{ backgroundColor: '#fff' }}>
          <Toolbar sx={{ justifyContent: 'space-between', padding: smScreen ? '0px 2rem !important' : '0px 5rem !important' }}>
            <img src={logo} style={{ width: smScreen ? "150px" : '15%' }} />

            <Button variant='container' onClick={()=>history.push('/login')}
            className='buttonGradient borderRadius boxshadow font600 fontSize16'>Login</Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment >
  );
}
