import React from 'react';
import { Container, Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import choosebg from '../img/choosebg.png';
import Picture from '../img/Rectangle11.png';
import Picture1 from '../img/Rectangle12.png';
import Picture2 from '../img/Rectangle13.png';
import { useMediaQuery } from '@mui/material';

function ChooseUs() {
  const smScreen = useMediaQuery('(max-width:600px)');
  const xsScreen = useMediaQuery('(max-width:375px)');

  return (
    <Box className='displayStyle py80' sx={{
      backgroundImage: `url(${choosebg})`,
      backgroundPosition: '85%',
      backgroundRepeat: 'no-repeat'
    }}>
      <Container className='p-0 mobilepadding'>
        <Grid container spacing={5} sx={{ flex: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className='font600 fontSize40' gutterBottom>
              Why choose us?
            </Typography>
            <Typography variant="body1" paragraph className='fontSize18 font400 lineHeight'>
              With expertise in niche marketing, innovative solutions, and a dedicated team focused on ROI and engagement, GrooveNexus is your partner for impactful brand growth
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Expertise in niche marketing areas </Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Innovative and customized solutions </Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Strong focus on ROI and engagement metrics </Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Dedicated team of creative and technical experts </Typography>} />
              </ListItem>

            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',

            }}>
              <Box component="img" src={Picture1} alt="Main Image" sx={{ width: xsScreen ? '250px' : smScreen ? '250px' : '362px', height: xsScreen ? '180px' : smScreen ? "180px" : '254px', mb: 2, }} className='borderRadius imgStyle' />
              <Box className='displayStyle1' sx={{ gap: 2 }}>
                <Box component="img" src={Picture} alt="Image 2" sx={{ width: xsScreen ? '135px' : smScreen ? "170px" : '280px', height: xsScreen ? '120px' : smScreen ? "155px" : '196px' }} className='borderRadius imgStyle' />
                <Box component="img" src={Picture2} alt="Image 3" sx={{ width: xsScreen ? '130px' : smScreen ? "170px" : '280px', height: xsScreen ? '115px' : smScreen ? '145px' : '185px' }} className='borderRadius imgStyle' />
              </Box>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  );
}
export default ChooseUs;
