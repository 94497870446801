import React from 'react';
import { Container, Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Picture from '../img-landing/Picture.png';
import UserThumb from '../img-landing/UserThumb.png';
import weR from '../img-landing/weR.png';
import weR1 from '../img-landing/weR1.png';
import icon from '../img-landing/Icons.png';
import { useMediaQuery } from '@mui/material';

function WhoWeAre() {
  const smScreen = useMediaQuery('(max-width:600px)');
  const xsScreen = useMediaQuery('(max-width:375px)');

  return (
    <Box className='displayStyle containerbgColor py80' sx={{
      backgroundImage: `url(${icon})`,
      backgroundPosition: '10%',
      backgroundRepeat: 'no-repeat'
    }}>
      <Container className='p-0 mobilepadding'>
        <Grid container spacing={5} sx={{ flex: 1 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Box position={'relative'}>
                <Box component="img" src={Picture} alt="Main Image" sx={{ width: xsScreen ? '265px' : smScreen ? '300px' : '376px', height: xsScreen ? '175px' : smScreen ? '200px' : '231px', mb: 2, }} className='borderRadius imgStyle' />
                <Box component="img" src={UserThumb} alt="Main Image" sx={{ width: '17%', mb: 2, position: 'absolute', top: '50%', right: '-7%' }} className=' userImg' />
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box component="img" src={weR1} alt="Image 3" sx={{ width: xsScreen ? '150px' : smScreen ? "190px" : '304px', height: xsScreen ? '110px' : smScreen ? '130px' : '179px', }} className='borderRadius imgStyle' />
                <Box component="img" src={weR} alt="Image 2" sx={{ width: xsScreen ? '130px' : smScreen ? "160px" : '232px', height: xsScreen ? '100px' : smScreen ? "130px" : '179px', }} className='borderRadius imgStyle' />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className='font600 fontSize40' gutterBottom>
              Who We Are?
            </Typography>
            <Typography variant="body1" paragraph className='fontSize18 font400 lineHeight'>
              GrooveNexus stands at the intersection of creativity and innovation, dedicated to transforming brand visions into compelling digital narratives.
              With a focus on the music and entertainment, we offer tailor-made marketing solutions that resonate with your target audience, ensuring maximum engagement and increased Brand loyalty.
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Pioneers in creative marketing strategies </Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Specialized in the music and entertainment sector</Typography>} />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <CheckIcon sx={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography className='fontSize18 font400 lineHeight'>Committed to delivering measurable results</Typography>} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default WhoWeAre;
