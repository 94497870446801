// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDzP9AdjysC_hjaQy-NGe2ZXco1CEMAAsA",
  authDomain: "groovenexus-brand.firebaseapp.com",
  databaseURL: "https://groovenexus-brand-default-rtdb.firebaseio.com",
  projectId: "groovenexus-brand",
  storageBucket: "groovenexus-brand.firebasestorage.app",
  messagingSenderId: "473478223727",
  appId: "1:473478223727:web:01da55ae740d194e99fa9a",
  measurementId: "G-6R0EN5JFJT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber, db, storage }
