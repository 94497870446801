import React from "react";
import { Card, CardContent, CardMedia, Typography, Button, Stack, Box, Chip } from "@mui/material";

// ---------------- card style ----------
const cardStyle = {
  borderRadius: 2, marginBottom: 3
}
const typographyStyle = {
  backgroundColor: "#fdeaea", fontWeight: "bold", padding: 1, fontSize: '14px', color: '#454545'
}
const ReusableCardView = ({ title, Component }) => {
  return (
    <Card sx={cardStyle}>
      <CardContent sx={{ padding: '0px 0px' }} >
        {/* About the Event Header */}
        <Typography variant="h6" sx={typographyStyle}>
          {title}
        </Typography>

        {Component}

        
      </CardContent>
    </Card>
  );
};

export default ReusableCardView;
