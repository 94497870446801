import React, { useEffect, useState } from "react";
import Tablecomponent from "../reusableComponents/Tablecomponent";
import { Box, Button, Menu, MenuItem, Paper } from "@mui/material";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import { useHistory } from "react-router-dom";
import ApiService from "../../_services/ApiService";
import { useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const MySubmission = () => {
  const history = useHistory();
  const userData = useSelector((state) => state.userData.userData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [submissionList, setSubmissionList] = useState([]);
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  useEffect(() => {
    if (userData) {
      ApiService.get('brand_event_submission', { offset: page, limit: rowsPerPage, user: userData?.id }).then((res) => {
        let response = res?.data?.results
        if (res.status == 200 || res.status == 201) {
          setSubmissionList(response)
        }
      }).catch(error => {
        console.log('submission: error', error)
      })
    }
  }, [userData, page, rowsPerPage])

  const rows = submissionList || [];

  const columns = [
    {
      id: 'event', label: 'Event Name', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer'>{row?.event?.event_name}</Box>
        )
      }
    },
    {
      id: 'location', label: 'Location', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer'>{row?.event?.location}</Box>
        )
      }
    },
    { id: 'suggested_amount', numeric: true, field: "suggested_amount", disablePadding: false, label: 'Suggested Amount', },
    { id: 'proposed_amount', numeric: true, field: "proposed_amount", disablePadding: false, label: 'Proposed Amount', },
    { id: 'description', numeric: true, field: "description", disablePadding: false, label: 'Description', },
    {
      id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer' ><Button
            id={`basic-button-${row.id}`}
            aria-controls={menuRowId === row.id ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuRowId === row.id ? 'true' : undefined}
            onClick={(event) => handleMenuClick(event, row.id)}
          >
            <MoreVertIcon />
          </Button>
            <Menu
              onClose={handleClose}
              id={`basic-menu-${row.id}`}
              anchorEl={anchorEl}
              open={menuRowId === row.id}
              MenuListProps={{
                'aria-labelledby': `basic-button-${row.id}`,
              }}
            >
              <MenuItem className='px-4 py-2 d-flex gap-3' onClick={() => history.push('/event/view-submission', { eventData: row, page:'submission' })} ><RemoveRedEyeIcon />View</MenuItem>
            </Menu>
          </Box>
        )
      }

    },
  ];

  const backClickHistory = () => {
    history.push('/event')
  }
  return (
    <Box p={4}>
      <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={'My Submissions'} subHeading={'Effortlessly Create, Manage, and Track Your Gig Opportunities'} />

      <Paper>
        <Tablecomponent
          columns={columns}
          rowsPerPage={rowsPerPage}
          page={page}
          data={rows}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default MySubmission;
