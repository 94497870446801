import React from "react";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

const ReusableRadioGroup = ({ label, options, value, onChange }) => {
  return (
    <FormControl>
      <FormLabel sx={{ fontWeight: "bold", color: "#3D3D3D" }}>{label}</FormLabel>
      <RadioGroup row value={value} onChange={(e) => onChange(e, options)}>
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.Name}  // Use "name" instead of "Name"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default ReusableRadioGroup;
