import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      industryList: 'brand/industry/',
      saveProfile: 'brand/users',
      eventList: 'brand/events',
      deliverables: 'brand/deliverables/',
      brand_event: 'brand/brand_event',
      brand_event_view: 'brand/events',
      brand_event_submission: 'brand/brand_events/',
      brand_event_submission_view: 'brand/brand_event/view/',
      // --------- offer ----
      brand_offers: 'brand/offers',
      offers_creation: 'brand/offers_creation/',
      offers_requirements: 'brand/offers_requirements/',
      offers_eligible: 'brand/offers_eligible/',

      // ------------ gig-management ---------
      gigs: 'brand/gigs/',
      gigsview: 'brand/gigs',
      gigscreate: 'brand/gigs_creation/',
      gigsType: 'brand/gigs_type/',

      // ------------ internship-management ---------
      internships: 'brand/internships/',
      internshipsview: 'brand/internships',
      intershipType: 'brand/internship_type/',
      intershipSkills: 'brand/skills/',
      intershipBenefit: 'brand/benefits/',
      Internshipscreate: 'brand/internship_creation/',

      // ----------- d3chart
      offerApplicantClick: 'brand',


    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Token ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      // throw new Error('API request failed:', { error });
      return error.response;
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null, pathParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (pathParam) {
        if (endpoint.endsWith('/')) {
          endpoint = `${endpoint}${pathParam}/`;
        } else {
          endpoint = `${endpoint}/${pathParam}/`;
        }
      }
      if (searchParam) {
        const searchParams = new URLSearchParams(searchParam).toString();
        endpoint = `${endpoint}?${searchParams}`;
      }

      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'token ' + getSelfToken() }
      });

      return response;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  }
}



export default new APIService(BASE_URL);
