import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box, Typography, Select, MenuItem } from "@mui/material";

const D3LineChart = ({
  data = [],
  xKey = "day",
  yKey = "value",
  title = "Line Chart",
  color = "#818CF8",
  width = 400,
  height = 250,
  timeFrames = ["This Week", "Last Week"],
  onTimeFrameChange,
}) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data.length) return;

    // Set margins
    const margin = { top: 20, right: 20, bottom: 30, left: 50 };

    // Select and clear previous SVG
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Set scales
    const xScale = d3
      .scalePoint()
      .domain(data.map((d) => d[xKey]))
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d[yKey]) || 100])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Define line generator
    const line = d3
      .line()
      .x((d) => xScale(d[xKey]))
      .y((d) => yScale(d[yKey]))
      .curve(d3.curveMonotoneX);

    // Append X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).tickSize(0))
      .selectAll("text")
      .style("font-size", "12px");

    // Append Y-axis
    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale).ticks(5));

    // Append line path
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-width", 2)
      .attr("d", line);

    // Append data points
    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d[xKey]))
      .attr("cy", (d) => yScale(d[yKey]))
      .attr("r", 4)
      .attr("fill", color);
  }, [data, xKey, yKey, color, width, height]);

  return (
    <Box p={2}>
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        {timeFrames.length > 0 && (
          <Select
            defaultValue={timeFrames[0]}
            onChange={(e) => onTimeFrameChange?.(e.target.value)}
            size="small"
            variant="standard"
          >
            {timeFrames.map((frame) => (
              <MenuItem key={frame} value={frame}>
                {frame}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {/* Chart Section */}
      <svg ref={svgRef} width={width} height={height}></svg>
    </Box>
  );
};

export default D3LineChart;
