export const errorLogin = (error) => {
    let errorMessage = "An error occurred. Please try again.";

    switch (error.code) {
        case "auth/user-not-found":
            return errorMessage = "Email not found. Please sign up to create an account.";
        case "auth/wrong-password":
            return errorMessage = "Incorrect password. Please try again.";
        case "auth/invalid-email":
            return errorMessage = "Invalid email format.";
        case "auth/user-disabled":
            return errorMessage = "This account has been disabled.";
        case "auth/missing-password":
            return errorMessage = "Password not set for this account. Please click 'Forgot Password' to generate your password.";
        default:
            return errorMessage = error.message;
    }
}