import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, CircularProgress
} from '@mui/material';

export default function TableComponent({ chartData, loader, title }) {
  console.debug("tablecomponent: users", { chartData })

  const usersWeek = chartData?.week_wise
    ? Object.entries(chartData?.week_wise).flatMap(([week, entry]) =>
      entry?.users?.map(user => ({ week, user }))
    )
    : [];
  console.debug("tablecomponent: users", { usersWeek })

  // console.debug("Object.values(chartData?.week_wise)", Object?.keys(chartData?.week_wise), users)
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: '120px', fontWeight: 600 }}>Name</TableCell>
            <TableCell sx={{ maxWidth: '150px', fontWeight: 600 }}>Email</TableCell>
            <TableCell sx={{ maxWidth: '150px', fontWeight: 600 }}>{title == "Clicks" ? "Clicks" : 'Applied'}</TableCell>
            <TableCell sx={{ maxWidth: '150px', fontWeight: 600 }}>Week</TableCell>
            <TableCell sx={{ maxWidth: '150px', fontWeight: 600 }}>Day</TableCell>
            <TableCell sx={{ maxWidth: '150px', fontWeight: 600 }}>{title == "Clicks" ? 'Last Clicked' : 'Last Applied'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : usersWeek.length > 0 ? (
            usersWeek.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ maxWidth: '150px' }}>{row?.user?.name || "Unknown"}</TableCell>
                <TableCell sx={{ maxWidth: '150px' }}>{row?.user?.email || "N/A"}</TableCell>
                {/* <TableCell sx={{ maxWidth: '150px' }}>{row?.user?.clicks || 0}</TableCell> */}
                <TableCell sx={{ maxWidth: '150px' }}>{title == "Clicks" ? row?.user?.clicks : row?.user?.applied || 0}</TableCell>
                <TableCell sx={{ maxWidth: '150px' }}>{row?.week || 0}</TableCell>
                {/* {weekColumns.map((week, index) => (
                  <TableCell key={index}>
                    {console.debug("Object.values(chartData?.week_wise) 112", chartData.week_wise, week)}
                    {week || "-"}
                  </TableCell>
                ))} */}
                <TableCell sx={{ maxWidth: '150px' }}>{row?.user?.day || "N/A"}</TableCell>
                <TableCell sx={{ maxWidth: '150px' }}>{title == "Clicks" ? row?.user?.last_clicked : row?.user?.last_applied || "N/A"}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography>No Data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
