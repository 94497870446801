import React from 'react'

const onlyEndSpaceAllowed = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*\s*$/;// to allow only chracters and disallow space at start
const startEndNotSpaceNotAllowed = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*$/; // to allow only chracters and disallow space at start and end
const numberAndTextAllowed = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/; // to allowe numbers and text only and disallow start and end
const allowAll = /(?!^ +$)^.+$/; //to allow all except all spaces and blancks
const allowAllWithLineBreak = /(?!^ +$)^.+$/s; // tp allow all including line breaks
const allowAlaphaNumericWithEndSpace = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*\s*$/; // to allowe numbers and text only and allow end space
const onlyNumber = /^[0-9]+$/;
const alphaNumSpecial = /[^a-zA-Z0-9\s\-._]/g;
const webUrl = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

// end

export const regexValidator = (strInput, fieldType) => {
   
   switch(fieldType)
   {
        case 'text':{
          const regex = onlyEndSpaceAllowed;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'textSpaceEndStart':{
          const regex = startEndNotSpaceNotAllowed;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'number':{
          const regex = onlyNumber;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumeric':{
          const regex =  numberAndTextAllowed
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumericEndSpace':{
          const regex =  allowAlaphaNumericWithEndSpace
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'alphanumspecial':{
          const regex =  alphaNumSpecial
          const isValid = !regex.test(strInput)
          return isValid;
        }
        case 'descriptionText':{
          const regex = allowAllWithLineBreak;
          const isValid = regex.test(strInput)
          return isValid;
        }
        case 'URL':{
          const regex = webUrl;
          const isValid = regex.test(strInput)
          return isValid;
        }
        // end
        default:{
          return false;
        }
   }
}

