import * as React from 'react';
import { Container, Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

export default function Counts() {

    return (
        <Box className='countbglinear'>
            <Box className='bgImgcount' sx={{ py: "40px" }}>
                <Container className='p-0 mobilepadding'>
                    <Box className='displayStyle  mobilecount'>
                        <Box className='displayStyle'>
                            <Typography className='font300 fontSize48 colorWhite'>10+</Typography>
                            <Typography sx={{width:'50%'}} className='font600 fontSize16 colorWhite'>YouTube Channels</Typography>
                        </Box>
                        <Box className='displayStyle'>
                            <Typography className='font300 fontSize48 colorWhite'>11b+</Typography>
                            <Typography sx={{width:'50%'}} className='font600 fontSize16 colorWhite'>YouTube Impressions</Typography>
                        </Box>
                        <Box className='displayStyle'>
                            <Typography className='font300 fontSize48 colorWhite'>2k+</Typography>
                            <Typography sx={{width:'50%'}} className='font600 fontSize16 colorWhite'>Artists &
                                Creators</Typography>
                        </Box>
                        <Box className='displayStyle'>
                            <Typography className='font300 fontSize48 colorWhite'>50+</Typography>
                            <Typography  className='font600 fontSize16 colorWhite'>Shoots </Typography>
                        </Box>
                        <Box className='displayStyle '>
                            <Typography className='font300 fontSize48 colorWhite'>25+</Typography>
                            <Typography sx={{width:'50%'}} className='font600 fontSize16 colorWhite'>Collage
                                Associations </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}
