import React, { useState } from 'react';
import { Box, IconButton, Card, CardMedia, Typography, Container, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import slider1 from '../img-landing/slider/carousel.png'
import slider2 from '../img-landing/slider/carousel1.png'
const images = [
    { id: "1", image: slider1, label: 'Social Media Content Creation ', value: "Craft unique, engaging, and visually appealing content tailored to your brand’s voice and audience's preferences" },
    { id: "2", image: slider2, label: 'Social Media Management', value: 'Comprehensive management from content scheduling to monitoring and engaging with your audience to build relationships.' },
];


const Experties = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    return (
        <Box className='containerbgColor py80'>
            <Container className='p-0 mobilepadding'>
                <Box className='displayStyle1'>
                    <Box>
                        <Typography variant="h4" className='font600 fontSize40' gutterBottom>
                            Our Expertise
                        </Typography>
                        <Typography variant="body1" paragraph className='fontSize18 font400 lineHeight'>
                            Tailored Services to Elevate Your Brand's Digital Presence
                        </Typography>
                    </Box>

                </Box>
                <Box display="flex" alignItems="center" mt={5} position={'relative'} >
                    <Box display="flex" overflow="hidden" width="100%">
                        <Box display="flex" 
                            sx={{
                                transform: `translateX(-${currentIndex * 100}%)`,
                                transition: 'transform 0.5s ease',
                            }}>
                            {images.map((img, index) => (
                                <Box key={index} className='boxshadownone borderRadius displayStyle expertiesMobile' sx={{ minWidth: '100%', gap: '75px', padding:'0px 25px' }}>
                                    <CardMedia component="img" image={img.image} alt={`Image ${index + 1}`} />
                                    <Box>
                                        <Typography className='font600 fontSize27' sx={{color: '#898989'}}>{img.label}</Typography>
                                        <Typography className='font400 fontSize18 lineHeight'  mt={2}>{img.value}</Typography>
                                    </Box>
                                </Box>
                            ))}

                        </Box>
                    </Box>
                    <Box className='displayStyle justifySpacebtw expertiesBtnMobile' position={'absolute'} width={'100%'}  >
                        <IconButton className={'borderRadius100 slidbtn bgWhite'} onClick={handlePrev} disabled={currentIndex === 0}>
                            <ArrowBackIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <IconButton className={'borderRadius100 slidbtn bgWhite'} onClick={handleNext} disabled={currentIndex === images.length - 1}>
                            <ArrowForwardIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Experties;
