import React from 'react'
import backgroundImage from '../../img/bg_image_texture4.png'
import { Link } from 'react-router-dom'
import { Card, CardContent } from '@mui/material'
import { useSelector } from 'react-redux'

const ProfileFinal = () => {
    const userData = useSelector((state) => state.userData.userData);
    return (
        <div className="vh-100 mt-5" style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <Card sx={{ maxWidth: 500, margin: 'auto' }}>
                <CardContent>
                    <div className="thankYou-modal-text">
                        <p>Thank You</p>
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-column fullPage">
                        <div className='text-center'>
                            <div className="text-center mb-4">
                                <h5 style={{ fontWeight: '900', color: '#A6A6A6' }}>For completion  on your  profile  You're all set,</h5>
                            </div>
                            {userData?.avatar_url && <img src={userData?.avatar_url} alt="Img Not found" className='w-50' />}
                            <Link to={{ pathname: "/event" }}><button className='gn-actionbtn mt-4 mb-4'>Explore Opportunities Now.</button></Link>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default ProfileFinal;