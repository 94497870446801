export const SCHEDULE_CHOICES = [
    { id: 1, value: "weekend-availability", Name: "Weekend Availability" },
    { id: 2, value: "day-shift", Name: "Day Shift" },
    { id: 3, value: "night-shift", Name: "Night Shift" },
    { id: 4, value: "monday-friday", Name: "Monday-Friday" }
];

export const TYPESWORKPLACE_CHOICES = [
    { id: 1, value: "on-site", Name: "On-Site" },
    { id: 2, value: "hybrid", Name: "Hybrid" },
    { id: 3, value: "remote", Name: "Remote" }
];

export const TYPESEMPLOYMENT_CHOICES = [
    { id: 1, value: "full-time", Name: "Full-time" },
    { id: 2, value: "part-time", Name: "Part-time" },
    { id: 3, value: "traineeship", Name: "Traineeship" },
    { id: 4, value: "internship", Name: "Internship" }
];

export const STIPEND_CHOICES = [
    { id: 1, value: "free", Name: "Free" },
    { id: 2, value: "custom", Name: "Custom" }
];
