import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography, List, ListItem, ListItemText, Grid, Paper, Tabs, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import { useHistory } from "react-router-dom";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import ReusableCardView from "../reusableComponents/ReusableCardView";
import NoResults from "../../components/reusableComponents/NoResults";
import SingleDataCard from "../reusableComponents/SingleDataCard";
import D3LineChart from "../reusableComponents/D3LineChart";
import { CardSkeletonCollab, CardSkeletonCount } from "../../components/reusableComponents/SkeletonCard";
import GigTableView from "./gigTableView";
import { getDateAsPerFormat } from "../../_helpers/reusablefunctions/dateSetter";
import ReusableTabs from "../reusableComponents/reusableTabs";
import { formatCardData, getCurrentMonthAndWeek, transformData } from "../reusableComponents/formatCardData";
import Tablecomponent from "../reusableComponents/Tablecomponent";

const sampleData1 = [
    { day: "Mon", value: 22 },
    { day: "Tue", value: 8 },
    { day: "Wed", value: 24 },
    { day: "Thurs", value: 30 },
    { day: "Fri", value: 35 },
    { day: "Sat", value: 12 },
    { day: "Sun", value: 20 },
];

const sampleData2 = [
    { month: "Jan", value: 50 },
    { month: "Feb", value: 70 },
    { month: "Mar", value: 60 },
    { month: "Apr", value: 80 },
    { month: "May", value: 90 },
    { month: "Jun", value: 40 },
];
const ViewGigManagement = () => {
    const history = useHistory();
    const location = useLocation()?.state;
    const locationEventDetail = location?.eventData
    const [loader, setLoader] = useState(false);
    const [offerViewList, setOfferViewList] = useState(locationEventDetail);
    const [value, setValue] = React.useState(0);
    const [formattedData, setFormattedData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartDataApply, setChartDataApply] = useState([]);
    const [chartFullData, setChartFullData] = useState([]);
    const [chartFullDataApply, setChartFullDataApply] = useState([]);
    const { month, week } = getCurrentMonthAndWeek();

    const backClickHistory = () => {
        history.push('/gig-management')
    }

    // -----------------

    useEffect(() => {
        setLoader(true);

        Promise.all([
            ApiService.get(
                'offerApplicantClick',
                { month, week },
                `click_analytics/gig/${locationEventDetail?.id}/`
            ),
            ApiService.get(
                'offerApplicantClick',
                { month, week },
                `apply_analytics/gig/${locationEventDetail?.id}/`
            )
        ])
            .then(([offerRes, applyRes]) => {
                if (
                    (offerRes?.status === 200 || offerRes?.status === 201) &&
                    (applyRes?.status === 200 || applyRes?.status === 201)
                ) {
                    const formattedCardData = formatCardData([
                        { label: 'Gig Clicks', count: offerRes?.data?.data?.total_clicks },
                        { label: 'Number of Applicants', count: applyRes?.data?.data?.total_applied }
                    ]);
                    setChartFullData(offerRes?.data?.data)
                    setChartFullDataApply(applyRes?.data?.data)
                    setFormattedData(formattedCardData);
                    const transformed = transformData(offerRes?.data?.data?.week_wise);
                    setChartData(transformed);
                    const transformedApply = transformData(applyRes?.data?.data?.week_wise);
                    setChartDataApply(transformedApply);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            })
            .finally(() => setLoader(false));
    }, [month, week, locationEventDetail?.id]);

    const handleTimeFrameChange = (value) => {
        setChartData(value === "This Week" ? sampleData1 : sampleData2);
    };

    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={`${offerViewList?.Title} Gig Details`} />
                <Box>
                    {/* <ReusableButton children={'Close gig'} variant="contained"
                        classNames={'gn-actionbtn'} /> */}
                    {/* <ReusableButton children={'Edit'} variant="contained"
                        classNames={'gn-actionbtn'} /> */}
                </Box>
            </Box>
            {/* --------------- cards ------------ */}
            <Box sx={{ display: 'flex', gap: '20px' }}>
                {loader ? (
                    ['', '', '', ''].map((d, i) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
                            <CardSkeletonCount />
                        </Grid>
                    ))
                ) : (formattedData && formattedData.map((item, index) => (
                    <SingleDataCard
                        key={index}
                        cardHeading={item.label}
                        count={item.value}
                        bg={item.bgcolor}
                        cardbg={item.cardbg}
                    />)
                ))}
            </Box>

            {/* 0---------------- tab switch analytics and offer details ------------- */}

            <Box sx={{ width: '100%', mt: 3 }}>
                <ReusableTabs setValue={setValue} value={value} />
            </Box>

            {value == 0 ? <Grid container spacing={2} mt={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    {/* -------- overview ---------------- */}
                    <ReusableCardView title="Overview" Component={
                        <Box p={3}>
                            <Box display="flex" alignItems="center" gap={2} sx={{ marginBottom: 1 }}>

                                <Typography variant="body2" sx={{ marginLeft: "auto", color: "gray" }}>
                                    Posted On {getDateAsPerFormat(offerViewList?.created_at, "DD Mon, YYYY")}
                                </Typography>
                            </Box>
                            <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: '18px' }}>
                                {offerViewList?.Title}
                            </Typography>
                            {/* About the Offer */}
                            <Typography variant="span" sx={{ fontWeight: 'normal', color: "#7A7A7A" }} dangerouslySetInnerHTML={{ __html: offerViewList?.AboutGigWork }} />

                            {/* Valid Till */}
                            <Typography variant="body2" sx={{ fontWeight: "bold", marginTop: 1, fontSize: '16px', }}>
                                Deadline :
                                <Typography component="span" sx={{ fontWeight: "normal", color: "#7A7A7A" }}>
                                    {" "}{offerViewList?.DeadLineDate}
                                </Typography>
                            </Typography>

                        </Box>
                    } />
                </Grid>

                {/* --------- term & condition */}
                <Grid item xs={12} sm={12} md={6} lg={6} >

                    <ReusableCardView title="Steps to Complete the Task " Component={
                        offerViewList?.Completethetask ? <Box p={3}>
                            <Typography variant="span" sx={{ fontWeight: 'normal', color: "#7A7A7A" }} dangerouslySetInnerHTML={{ __html: offerViewList?.Completethetask }}
                            />
                        </Box>
                            : loader ? (
                                [''].map((d, i) => (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
                                        <CardSkeletonCollab />
                                    </Grid>
                                ))
                            )
                                :
                                <NoResults eventNoData={'No steps found '} />
                    }
                    />
                </Grid>
                {offerViewList?.FAQ && <Grid item xs={12} sm={12} md={6} lg={6} >

                    <ReusableCardView title="FAQ" Component={
                        <Box p={3}>
                            <Typography variant="span" sx={{ fontWeight: 'normal', color: "#7A7A7A" }} dangerouslySetInnerHTML={{ __html: offerViewList?.FAQ }}
                            />
                        </Box>
                    }
                    />
                </Grid>}
                {offerViewList?.Requirements && <Grid item xs={12} sm={12} md={6} lg={6} >

                    <ReusableCardView title="Requirements" Component={
                        <Box p={3}>
                            <Typography variant="span" sx={{ fontWeight: 'normal', color: "#7A7A7A" }} dangerouslySetInnerHTML={{ __html: offerViewList?.Requirements }}
                            />
                        </Box>
                    }
                    />
                </Grid>}
            </Grid>
                :
                <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        {/* -------- User Insight  ---------------- */}
                        <ReusableCardView title="User Insight " Component={
                            chartData.length ? <Box >
                                <D3LineChart
                                    data={chartData}
                                    xKey={chartData[0]?.day ? "day" : "month"}
                                    yKey="value"
                                    title="Gig Clicks"
                                    color="#4F46E5"
                                    width={500}
                                    height={250}
                                // timeFrames={["This week", "This month"]}
                                // onTimeFrameChange={handleTimeFrameChange}
                                // timeFrames={timeFrames}
                                // onTimeFrameChange={(timeFrame) => {
                                //     setSelectedTimeFrame(timeFrame);
                                //     // fetchData(selectedMonth, timeFrame === "This Week" ? currentWeek : parseInt(timeFrame.split(" ")[1]));
                                // }}
                                />
                            </Box>
                                :
                                loader ? (
                                    [''].map((d, i) => (
                                        <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
                                            <CardSkeletonCollab />
                                        </Grid>
                                    ))
                                ) : null
                        } />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        {/* -------- Application Over Time     ---------------- */}
                        <ReusableCardView title="Application Over Time    " Component={
                            chartDataApply.length ? <Box >
                                <D3LineChart
                                    data={chartDataApply}
                                    xKey={chartDataApply[0]?.day ? "day" : "month"}
                                    yKey="value"
                                    title="Applied Applicants"
                                    color="#4F46E5"
                                    width={500}
                                    height={250}
                                // timeFrames={["This week", "This month"]}
                                // onTimeFrameChange={handleTimeFrameChange}
                                // timeFrames={timeFrames}
                                // onTimeFrameChange={(timeFrame) => {
                                //     setSelectedTimeFrame(timeFrame);
                                //     // fetchData(selectedMonth, timeFrame === "This Week" ? currentWeek : parseInt(timeFrame.split(" ")[1]));
                                // }}
                                />
                            </Box> :
                                loader ? (
                                    [''].map((d, i) => (
                                        <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
                                            <CardSkeletonCollab />
                                        </Grid>
                                    ))
                                ) : null
                        } />
                    </Grid>
                    {/* -------------------- Applicants table data -------------- */}
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        {/* <GigTableView /> */}
                        <ReusableCardView title="Gig Clicks" Component={<Tablecomponent loader={loader} chartData={chartFullData} title="Clicks" /> }/>

                        <ReusableCardView title="Gig Applied" Component={<Tablecomponent loader={loader} chartData={chartFullDataApply} title="Applied" />} />
                 
                    </Grid>
                </Grid>
            }
        </Box>
    );
};

export default ViewGigManagement;
