import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ReusableHeadingComponents = ({bacHistory,clickHandlerBack, heading, subHeading }) => {
  return (
    <Box mb={3} display={'flex'} alignItems={'center'}>
      {bacHistory ? <IconButton onClick={clickHandlerBack}>
        <ArrowBackIcon />
      </IconButton> : null}
      <Box>
        <Typography variant="body1" sx={{ fontSize: '20px', color: '#4C4E64DE', fontWeight: 700 }}>
          {heading}
        </Typography>
        <Typography variant="caption" sx={{ fontSize: '14px', color: '#4C4E64DE', fontWeight: 400 }}>
          {subHeading}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReusableHeadingComponents;
