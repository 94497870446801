import { Box, Grid, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReusableCard from "../reusableComponents/ReusableCard";
import bgimage from "../../img/no_image.png"
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import { CardSkeletonCollab } from "../../components/reusableComponents/SkeletonCard";
import NoResults from "../../components/reusableComponents/NoResults";
import { formatEventDate } from "../../_helpers/reusablefunctions/dateSetter";
import { useHistory } from "react-router-dom";

const EventDetails = () => {
  const history = useHistory();
  const [eventList, setEventList] = useState([])
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // ------------------ Event List Get API Call 

  useEffect(() => {
    setLoader(true)
    ApiService.get('eventList', { offset: page, limit: rowsPerPage }).then((res) => {
      let response = res?.data?.data
      if (res.status == 200 || res.status == 201) {
        setCount(res?.data?.count)
        setEventList(response)
      }
      setLoader(false)
    }).catch(error => {
      setLoader(false)
      console.log(error)
    })
  }, [page, rowsPerPage])

  // -----------------

  function openSubmission(params) {
    history.push('/event/submission')
  }

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));


  return (
    <Box p={4}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
        <ReusableHeadingComponents heading={'Events Sponsorship'} subHeading={'Connect with sponsors and unlock opportunities to fuel your next big event.'} />
        <ReusableButton children={'My submission'} variant="contained"
          classNames={'gn-actionbtn'} onClick={openSubmission} />
      </Box>
      <Box mb={4}>
        <Grid container spacing={2}>
          {loader ? (
            ['', '', '', '', '', '', '', ''].map((d, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <CardSkeletonCollab />
              </Grid>
            ))
          ) : eventList.length ? (
            eventList.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <ReusableCard
                  image={data?.Featured_Banner || bgimage}
                  title={data?.EventName || ''}
                  // description="Supporting Sponsor ₹2,00,000"
                  date={formatEventDate(data?.EventFromDate, data?.EventToDate)}
                  audience={data?.Audience || ''}
                  // status="Active"
                  primaryAction={{ label: "" }}
                  secondaryAction={{ label: "View", onClick: () => history.push('/event/view-event', { eventData: data }) }}
                />
              </Grid>
            ))
          ) : (
            <NoResults eventNoData={'No Event Found'} />
          )}
        </Grid>


      </Box>
      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ '& p': { margin: '0px' } }}
      />
    </Box>
  );
};

export default EventDetails;
