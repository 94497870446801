import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ReusableComponent from "../reusableComponents/reusableInputComponents";
import { useHistory } from "react-router-dom";
import ReusableCardView from "../reusableComponents/ReusableCardView";
import RichTextEditor from "../reusableComponents/richTextEditior";
import { regexValidator } from "../../_helpers/reusablefunctions/regexValidator";
import ApiService from "../../_services/ApiService";
import useSnackDispatcher from "../../_helpers/reusablefunctions/snackBarComponent";
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import ReusableMultiComponent from "../reusableComponents/ReusableMultiComponent";
import ReusableCheckboxGroup from "../reusableComponents/reusableCheckboxGroup ";
import { useSelector } from "react-redux";

export default function CreateOfferManagement(params) {
    const { successSnack, errorSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const history = useHistory();
    const [targetAudience, setTargetAudience] = useState("");
    const [Howtoredeem, setHowtoredeem] = useState("");
    const [FAQ, setFAQ] = useState("");
    const [Abouttheoffer, setAbouttheoffer] = useState('');
    const [errors, setErrors] = useState('');
    const [Title, setTitle] = useState('');
    const [OfferURL, setOfferURL] = useState('');
    const [loader, setLoader] = useState(false);
    const [eligibleUser, setEligibleUser] = useState([]);
    const [eligibleUserSelection, setEligibleUserSelection] = useState([]);

    // const [eligibleRequirement, setEligibleRequirement] = useState([{Enable_Student:''},{Enable_Artist:''},{Enable_Creator:''}]);
    const [eligibleRequirement, setEligibleRequirement] = useState([
        {
            "id": 1,
            "Name": "Youth",
            "Value": 'Enable_Student'
        },
        {
            "id": 2,
            "Name": "Influencers",
            "Value": 'Enable_Creator'
        },
        {
            "id": 3,
            "Name": "Music Artists",
            "Value": 'Enable_Artist'
        },
        {
            "id": 4,
            "Name": "All Users"
        }
    ]);
    const [eligibleRequirementSelection, setEligibleRequirementSelection] = useState([]);

    // ---------------------- 
    useEffect(() => {
        // ApiService.get('offers_requirements').then((res) => {
        //     if (res?.status == 200 || res?.status == 201) {
        //         setEligibleRequirement(res?.data?.data)
        //     }
        // }).catch(error => {
        //     console.log("event:", error)
        // })
        ApiService.get('offers_eligible').then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setEligibleUser(res?.data?.data)
            }
        }).catch(error => {
            console.log("event:", error)
        })
    }, [])


    function backClickHistory(params) {
        history.push('/offer-management')
    }

    // --------------- validation handler ----------

    function validationInputs() {
        let errors = {};
        let isValid = true;

        if (Title?.trim() === '' || !regexValidator(Title, 'textSpaceEndStart')) {
            errors.Title = "Offer title is required.";
            isValid = false;
        }
        if (Abouttheoffer?.trim() === '') {
            errors.Abouttheoffer = "Offer description is required.";
            isValid = false;
        }
        if (OfferURL && !regexValidator(OfferURL, 'URL')) {
            errors.OfferURL = "Enter a valid URL.";
            isValid = false;
        }

        setErrors(errors); // Store errors in state
        return isValid;
    }

    // ------------- submit handler ---------

    function submitProfile(e) {
        e.preventDefault();
        let isValid = validationInputs()
        if (isValid) {
            const eligibleuser = eligibleUserSelection.map(delivey => delivey.id)

            let payload = {
                'Howtoredeem': Howtoredeem,
                'FAQ': FAQ,
                'Abouttheoffer': Abouttheoffer,
                'Title': Title,
                'OfferURL': OfferURL,
                'OfferType': eligibleuser,
                ...eligibleRequirementSelection
                    .filter(delivey => delivey?.Value)
                    .reduce((acc, delivey) => ({ ...acc, [delivey.Value]: true }), {}),
                'BrandId': userData?.id
            }

            setLoader(true);
            ApiService.post('offers_creation', payload).then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setLoader(false);
                    successSnack(formSnackbar?.profileSuccess?.successOfferMessage)
                    history.push('/offer-management')
                } else {
                    const errorData = res?.data || {};
                    Object.keys(errorData).forEach(field => {
                        let messages = errorData[field];

                        if (Array.isArray(messages)) {
                            messages = messages.join(', ');
                        } else if (typeof messages === "object" && messages !== null) {
                            messages = JSON.stringify(messages);
                        }

                        errorSnack(`${field}: ${messages}`);
                    });
                }
            }).catch(error => {
                setLoader(false);
                errorSnack(formSnackbar.errors.errorMsg);
                console.log(error)
            })
        }
    }

    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents bacHistory={true} clickHandlerBack={backClickHistory} heading={'Offer creation form'} subHeading={'Effortlessly Create, Manage, and Track Your Gig Opportunities'} />
                <Box>
                    {/* <ReusableButton variant="outlined" classNames={'gn-actionbtn-outline gn-actionbtn-color '} children="Save Draft" sx={{ color: '#6D788D', marginRight: '10px' }} /> */}
                    <ReusableButton loader={loader} classNames={'gn-actionbtn'} variant="outlined"
                        onClick={submitProfile}
                        children="Publish offer" sx={{ color: '#6D788D' }} />
                </Box>
            </Box>
            <Grid container spacing={2} >
                {/* Left Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Basic" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableComponent type="text"
                                label="Offer title"
                                placeholder={'e.g., "Spotify Premium - 50% Off for Students"'}
                                value={Title}
                                onChange={setTitle}
                                error={!!errors.Title}
                                helperText={errors.Title}
                            />
                            <ReusableComponent type="text"
                                label="Offer description"
                                rowsCount={5}
                                multiline={true}
                                value={Abouttheoffer}
                                onChange={setAbouttheoffer}
                                error={!!errors.Abouttheoffer}
                                helperText={errors.Abouttheoffer}
                            />
                            <ReusableComponent
                                type="text"
                                label="Offer url"
                                value={OfferURL}
                                onChange={setOfferURL}
                                error={!!errors.OfferURL}
                                helperText={errors.OfferURL}
                                placeholder="Offer url"
                            />
                        </Box>
                    } />
                </Grid>

                {/* Right Section */}
                <Grid item xs={12} md={6}>
                    <ReusableCardView title="Eligible users" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableMultiComponent label={'Target audience'}
                                options={eligibleUser}
                                selectedOptions={eligibleUserSelection}
                                setSelectedOptions={setEligibleUserSelection}
                                optionLabel="TypeName"
                            />
                        </Box>
                    } />
                </Grid>
                {/* --------------------- */}
                <Grid item xs={12} md={12}>

                    <ReusableCardView title="Eligibility & Targeting" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <ReusableCheckboxGroup
                                title="Requirements"
                                options={eligibleRequirement}
                                selectedOptions={eligibleRequirementSelection}
                                setSelectedOptions={setEligibleRequirementSelection}
                                optionLabel="Name"
                                selectAllLabel="All Users"
                            />
                            {/* <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#4C4E64', fontSize: '14px', mb: 2 }}>
                                {'Category'}
                            </Typography>
                            <ReusableMultiComponent label={'Category'}
                                options={eligibleUser}
                                selectedOptions={eligibleUserSelection}
                                setSelectedOptions={setEligibleUserSelection}
                                optionLabel="TypeName"
                            /> */}

                            <RichTextEditor
                                value={Howtoredeem}
                                onChange={setHowtoredeem}
                                placeholder="Write something..."
                            />


                        </Box>
                    } />
                </Grid>

                {/* ----------------- FAQS ------------------ */}
                <Grid item xs={12} md={12}>
                    <ReusableCardView title="FAQ" Component={
                        <Box px={3} pt={3}>
                            {/* <Box p={3}> */}
                            <RichTextEditor
                                value={FAQ}
                                onChange={setFAQ}
                                placeholder="Write something..."
                            />
                        </Box>
                    } />
                </Grid>
            </Grid>
            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} mb={3}>
                {/* <ReusableButton variant="outlined" classNames={'gn-actionbtn-outline gn-actionbtn-color '} children="Save Draft" sx={{ color: '#6D788D', marginRight: '10px' }} /> */}
                <ReusableButton loader={loader} classNames={'gn-actionbtn'} variant="outlined"
                    onClick={submitProfile}
                    children="Publish offer" sx={{ color: '#6D788D' }} />
            </Box>
        </Box>
    )

}