import React from "react";
import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
const ReusableButton = ({ variant = "contained", classNames, onClick, children, sx = {}, loader }) => {
  return (
    <Button variant={variant} className={classNames} onClick={onClick} sx={{ textTransform: "none", ...sx }}>
      {loader ? <CircularProgress size="20px"  color="inherit"/>
        : children
      }
    </Button>
  );
};

export default ReusableButton;
