import * as React from 'react';
import './App.css';
import './mediaquery.css';
import './js/bootstrap.Font.css'
import './js/bootstrap.min.css'
import { Container } from '@mui/material';
import Header from './pages/header';
import MainBanner from './pages/mainBanner';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WhoWeAre from './pages/whoWeAre';
import Marketing from './pages/marketing';
import ChooseUs from './pages/chooseUs';
import Footer from './pages/footer';
import ContactUs from './pages/contactUs';
import Campaign from './pages/campaign';
import Counts from './pages/counts';
import Experties from './pages/experties';
const theme = createTheme({
  palette: {
    primary: {
      main: '#e10050',
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <Container className='p-0 mobilepadding'>
        <Header />
        <MainBanner />
      </Container>
      <WhoWeAre />
      <Marketing />
      <Experties />
      <Counts />
      <Campaign />
      <ChooseUs />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}

export default App;


