import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const ReusableComponent = ({
    type = "text",
    label,
    value,
    onChange,
    options = [],
    getOptionLabel,
    error = false,
    helperText = "",
    disabledValue,
    rowsCount,
    multiline,
    placeholder,
    InputProps
}) => {
    const handleChange = (event, newValue) => {
        if (type === "numeric") {
            const numericValue = event.target.value.replace(/[^0-9]/g, "");
            onChange(numericValue);
        } else {
            onChange(newValue || event.target.value);
        }
    };

    if (type === "select") {
        return (
            <Autocomplete
                options={options}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} label={label} error={error} helperText={helperText} />
                )}
                sx={{ marginBottom: '20px' }}
                size="small"
            />
        );
    }

    return (
        <TextField
            multiline={multiline}
            label={label}
            value={value}
            onChange={handleChange}
            fullWidth
            error={error}
            helperText={helperText}
            disabled={disabledValue}
            size="small"
            rows={rowsCount}
            sx={{ marginBottom: '20px' }}
            placeholder={placeholder}
            InputProps={InputProps}
        />
    );
};

export default ReusableComponent;
