export const authApi = {
   LOGIN: process.env.REACT_APP_BASEURL + 'brand/login',
}
export const logoutApi = {
   LOGOUT: process.env.REACT_APP_BASEURL + 'brand/logout',

}
export const registerApi = {
   REGISTER: process.env.REACT_APP_BASEURL + 'brand/register',
}

export const userInfoApi = {
   VIEW: process.env.REACT_APP_BASEURL + 'brand/users/',
}