import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Typography, Stack } from "@mui/material";

const ReusableCheckboxGroup = ({ title, options, selectedOptions, setSelectedOptions, optionLabel, selectAllLabel }) => {
 // Find "All Users" (or select all) option dynamically
 const allOption = selectAllLabel ? options.find(option => option[optionLabel] === selectAllLabel) : null;
 const otherOptions = selectAllLabel ? options.filter(option => option[optionLabel] !== selectAllLabel) : options;

 // Check if all individual checkboxes (excluding "All Users") are selected
//  const areAllOtherChecked = otherOptions.every(option => selectedOptions.some(item => item.id === option.id));

 const handleChange = (event, option) => {
     const { checked } = event.target;

     if (selectAllLabel && option[optionLabel] === selectAllLabel) {
         // If "All Users" is checked, select all options
         setSelectedOptions(checked ? options : []);
     } else {
         // Add or remove selected option
         let newSelectedOptions = checked
             ? [...selectedOptions, option]
             : selectedOptions.filter(item => item.id !== option.id);

         // If all other checkboxes are selected, check "All Users"
         if (allOption && otherOptions.every(opt => newSelectedOptions.some(item => item.id === opt.id))) {
             newSelectedOptions = [...newSelectedOptions, allOption];
         } else {
             // If any option is unchecked, remove "All Users"
             newSelectedOptions = newSelectedOptions.filter(item => item[optionLabel] !== selectAllLabel);
         }

         setSelectedOptions(newSelectedOptions);
     }
 };

    return (
        <Stack spacing={1} mb={2}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#4C4E64', fontSize: '14px' }}>
                {title}
            </Typography>
            <FormGroup row>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        control={
                            <Checkbox
                                checked={selectedOptions.some((item) => item.id === option.id)}
                                onChange={(e) => handleChange(e, option)}
                                name={option[optionLabel]}
                                color="primary"
                            />
                        }
                        label={option[optionLabel]}
                    />
                ))}
            </FormGroup>
        </Stack>
    );
};

export default ReusableCheckboxGroup;
