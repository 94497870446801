
export function getDateAsPerFormat(cDate, dateFormat) {
  const localDate = new Date(cDate); // Convert date to local timezone
  const dayOfTheMonth = localDate.getDate();
  const month = localDate.getMonth();
  const year = localDate.getFullYear();

  switch (dateFormat) {
    case 'dd/MM/yyyy':
      return `${dayOfTheMonth}/${month + 1}/${year}`;
    case 'MM/dd/yyyy':
      return `${month + 1}/${dayOfTheMonth}/${year}`;
    case 'DD Mon, YYYY':
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const monthName = months[month];
      return `${dayOfTheMonth} ${monthName}, ${year}`;
    default:
      return `${dayOfTheMonth}/${month + 1}/${year}`;
  }
}

export function getTime(cDate) {
  const timestamp = cDate;
  const localTime = new Date(timestamp).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const gmtTime = new Date(timestamp).toUTCString();
  const time = new Date(timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  return time;
}

export function formatTimestamp(firestoreTimestamp) {
  const seconds = firestoreTimestamp.seconds;
  const date = new Date(seconds * 1000);
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export function formatDate(firestoreTimestamp) {
  const seconds = firestoreTimestamp.seconds;
  const date = new Date(seconds * 1000);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
};

export function formatEventDate(eventFromDate, eventToDate) {
  const fromDate = new Date(eventFromDate);
  const toDate = new Date(eventToDate);

  const options = { month: 'short', year: 'numeric' };
  const monthYear = new Intl.DateTimeFormat('en-US', options).format(fromDate);

  return `${fromDate.getDate()}-${toDate.getDate()} ${monthYear}`;
}

// Output: "20th-22nd Feb / 8am-10pm"

export function formatEventDateSetter(eventFromDate, eventFromTime, eventToDate, eventToTime) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Convert date strings to Date objects
  const fromDate = new Date(eventFromDate);
  const toDate = new Date(eventToDate);

  // Extract date and month
  const fromDay = fromDate.getDate();
  const fromMonth = months[fromDate.getMonth()];

  const toDay = toDate.getDate();
  const toMonth = months[toDate.getMonth()];

  // Format time
  const formatTime = (timeStr) => {
      let [hours, minutes] = timeStr.split(":").map(Number);
      let period = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12; // Convert to 12-hour format
      return `${hours}${minutes > 0 ? `:${minutes}` : ""}${period}`;
  };

  const formattedFromTime = formatTime(eventFromTime);
  const formattedToTime = formatTime(eventToTime);

  // Construct formatted date range
  const dateRange = fromMonth === toMonth 
      ? `${fromDay}-${toDay} ${fromMonth}` 
      : `${fromDay} ${fromMonth} - ${toDay} ${toMonth}`;

  return `${dateRange} / ${formattedFromTime}-${formattedToTime}`;
}

//  eg  05+ Days Ago ------------
export function formatDaysAgo(createdAt) {
  const createdDate = new Date(createdAt);
  const currentDate = new Date();
  
  const diffTime = Math.abs(currentDate - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return `${diffDays < 10 ? '0' : ''}${diffDays}+ Days Ago`;
}

