export function formatCardData(data) {
  const formattedData = data.map((item, index) => ({
    label: item.label,
    value: item.count,
    cardbg: index === 0 ? '#FFD8D8' : '#E6ECFF', // Different colors for first two cards
  }));

  // Add last two empty cards
  formattedData.push(
    { label: '', value: '', cardbg: '#FFF6CB' },
    { label: '', value: '', cardbg: '#FFE2D2' }
  );

  return formattedData;
}


export const getCurrentMonthAndWeek = () => {
  const today = new Date();
  const month = today.getMonth() + 1; // Months are 0-based, so add 1
  const day = today.getDate();

  // Get the first day of the current month
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  // Calculate week of the month (1-based)
  const week = Math.ceil((day + firstDay.getDay()) / 7);

  return { month, week };
};

const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const transformData = (apiData) => {
  const dayWiseClicks = {};

  // Initialize all days with 0 clicks
  dayOrder.forEach(day => {
    dayWiseClicks[day] = 0;
  });

  // Aggregate clicks per day
  Object.values(apiData).forEach(week => {
    Object.entries(week.day_wise).forEach(([day, clicks]) => {
      dayWiseClicks[day] += clicks;
    });
  });

  // Convert to array format
  return dayOrder.map(day => ({
    day: day.slice(0, 3), // Convert to "Mon", "Tue", etc.
    value: dayWiseClicks[day]
  }));
};