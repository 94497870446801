
import { Box, Grid, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReusableCard from "../reusableComponents/ReusableCard";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import { CardSkeletonCollab } from "../../components/reusableComponents/SkeletonCard";
import NoResults from "../../components/reusableComponents/NoResults";
import { useHistory } from "react-router-dom";

const OfferManagement = () => {
    const history = useHistory();
    const [offerManagement, setOfferManagement] = useState([])
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    // ------------------ Event List Get API Call 

    useEffect(() => {
        setLoader(true)
        ApiService.get('brand_offers', { offset: page, limit: rowsPerPage }).then((res) => {
            let response = res?.data?.data
            if (res.status == 200 || res.status == 201) {
                setCount(res?.data?.count)
                setOfferManagement(response)
            }
            setLoader(false)
        }).catch(error => {
            console.log(error)
            setLoader(false)
        })
    }, [])

    // -----------------

    function openSubmission(params) {
        history.push('/offer-management/create-offer')
    }


    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));


    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents heading={'Exclusive Offers Management'} subHeading={'Create and control time-bound offers to attract more fans and followers.'} />
                <ReusableButton children={'Create new offer'} variant="contained"
                    classNames={'gn-actionbtn'} onClick={openSubmission} />
            </Box>
            <Box mb={4}>
                <Grid container spacing={2}>
                    {loader ? (
                        ['', '', '', ''].map((d, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                <CardSkeletonCollab />
                            </Grid>
                        ))
                    ) : offerManagement.length ? (
                        offerManagement.map((data, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <ReusableCard
                                    title={data?.Title || ''}
                                    description={`Redemption: ${data?.SubTitle || '-'}`}
                                    OfferButtonLabel={data?.OfferButtonLabel}
                                    // date={formatEventDate(data?.EventFromDate, data?.EventToDate)}
                                    primaryAction={{ label: "", }}
                                    secondaryAction={{ label: "View", onClick: () => history.push('/offer-management/view-offer', { eventData: data }) }}
                                />
                            </Grid>
                        ))
                    ) : (
                        <NoResults />
                    )}
                </Grid>


            </Box>
            {/* Pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ '& p': { margin: '0px' } }}
            />
        </Box>
    );
};

export default OfferManagement;
