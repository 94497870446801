import React, { useEffect, useState } from 'react';
import './App.css';
import { Backdrop, } from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  BrowserRouter as Router, Switch, Route, Routes, Redirect, Navigate, BrowserRouter,
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import { useSelector, useDispatch } from 'react-redux'
// import RegisterUsers from './admin/RegisterUsers';
import { removeUserToken } from './_helpers/auth/secureToken'
import PageNotFound from './admin/PageNotFound';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SingupVerification from './admin/SingupVerification';
import SnackbarContainer from './module/Snackbar';
import { logoutHandler } from './_services/auth/logout.service';
import OTPVerification from './admin/otpVerification';
import PhoneAuth from './admin/firebase_authentication/loginOtp';
import EmailPasswordFirebaseSignup from './admin/firebase_authentication/emailPasswordFirebaseSignup';
import ForgotPasswordFirebase from './admin/firebase_authentication/forgotPasswordFirebase';
import Profile from './module/profile/profile';
import EventManagement from './module/Event/eventManagement';
import ViewEventManagement from './module/Event/viewEventManagement';
import MySubmission from './module/Event/mySubmission';
import GigManagement from './module/GigManagement/gigManagement';
import CreateGigManagement from './module/GigManagement/createGigManagement';
import ProfileFinal from './module/profile/profileFinal';
import OfferManagement from './module/OfferManagement/offerManagement';
import LandingContainer from './pages/landingContainer';
import ViewOfferManagement from './module/OfferManagement/viewOfferManagement';
import CreateOfferManagement from './module/OfferManagement/createOfferManagement';
import ViewGigManagement from './module/GigManagement/viewGigManagement';
import InternshipManagement from './module/Internship/internshipManagement';
import ViewInternshipManagement from './module/Internship/viewInternshipManagement';
import CreateInternshipManagement from './module/Internship/createInternshipManagement';

function useQuery() {
  return new URLSearchParams(window.location.search);
}
const theme = createTheme({
  palette: {
    secondary: {
      main: '#333'
    }
  },
});
function App() {
  const openBackdrop = useSelector((state) => state.loader.loader);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          <Route exact path="/">
            <LandingContainer />
          </Route>
          <Route exact path="/login">
            <PhoneAuth />
          </Route>
          {/* <PrivateRoute exact path="/">
            <Profile />
          </PrivateRoute> */}
          <PrivateRoute exact path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/profile-completion">
            <ProfileFinal />
          </PrivateRoute>
          {/* -------------- EVENT MANAGEMENT --------------- */}
          <PrivateRoute exact path="/event">
            <EventManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/event/view-event">
            <ViewEventManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/event/view-submission">
            <ViewEventManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/event/submission">
            <MySubmission />
          </PrivateRoute>
          {/* --------------- OFFER MANAGEMENT -------------- */}
          <PrivateRoute exact path="/offer-management">
            <OfferManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/offer-management/view-offer">
            <ViewOfferManagement />
          </PrivateRoute>
          <PrivateRoute exact path="/offer-management/create-offer">
            <CreateOfferManagement />
          </PrivateRoute>
          
          {/* --------------- GIG MANAGEMENT -------------- */}

          <PrivateRoute exact path="/gig-management">
            <GigManagement />
          </PrivateRoute>
          <PrivateRoute exact path="/gig-management/view-gig">
            <ViewGigManagement />
          </PrivateRoute>
          <PrivateRoute exact path="/gig-management/create-gig-management">
            <CreateGigManagement />
          </PrivateRoute>

          {/* ----------- Internship ------------ */}

          <PrivateRoute exact path="/internship">
            <InternshipManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/internship/view-internship">
            <ViewInternshipManagement />
          </PrivateRoute>

          <PrivateRoute exact path="/internship/create-internship">
            <CreateInternshipManagement />
          </PrivateRoute>

          <Route exact path="/otp-verification">
            <OTPVerification />
          </Route>
          <Route exact path="/thank-you/">
            <SingupVerification />
          </Route>
          <Route exact path="/register">
            {/* <Register /> */}
            <EmailPasswordFirebaseSignup />
          </Route>

          <Route exact path="/forgot-password">
            <ForgotPasswordFirebase />
          </Route>
          <Route path="/logout" render={() => {
            logoutHandler({})//called logout service
            removeUserToken();
            let callbackUrl = "/login";
            return <Redirect to={{ pathname: callbackUrl }} />;
          }
          }
          />
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
      <SnackbarContainer />
    </ThemeProvider>
  );
}

export default App;
