import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import bannerImg from '../img/bannerImg.png'

function MainBanner() {

  function movetocontact() {
    document.getElementById('contact').scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
    <Box className="displayStyle mobilemainbrand py80" sx={{ position: 'relative' }}>
      <Box sx={{ py: 4, width: '50%' }}>
        <Typography variant="p" gutterBottom className='font600  fontSize62 lineHeight70'>
          Elevate Your Brand to New Heights with
          <br />
          <Typography variant="span" className='textgradient fontSize62'>
            GrooveNexus
          </Typography>
        </Typography>
        <Typography sx={{ width: '90%', mt: 3 }} variant="subtitle1" gutterBottom className='fontSize16 '>
          Unleashing Potential Through Innovative Brand Strategies and Digital Excellence.
        </Typography>
        <Button onClick={movetocontact} variant="contained" className='buttonGradient font600 fontSize16 borderRadius border-none boxshadownone' sx={{ mt: 3 }}>
          GET STARTED
        </Button>
      </Box>
      <Box sx={{
        textAlign: 'center', position: 'absolute',
        right: '-20px',
        width: '760px',
        top: '41px'
      }}>
        <img src={bannerImg} alt="Brand Banner" width="100%" />
      </Box>
    </Box>
  );
}
export default MainBanner;
