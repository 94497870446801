import React, { useEffect, useState } from "react";
import { Button, Avatar, Box, Typography } from "@mui/material";
import ReusableComponent from "../reusableComponents/reusableInputComponents";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VerifiedIcon from '@mui/icons-material/Verified';
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import PhoneVerificationDialog from "../../admin/firebase_authentication/PhoneVerificationDialog";
import firebaseImageStore from '../../_helpers/reusablefunctions/firebaseImageStore';
import { regexValidator } from "../../_helpers/reusablefunctions/regexValidator";
import ApiService from "../../_services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import { formSnackbar } from "../../_services/_constants/snackbar.constant";
import { auth } from "../../firebase";
import { useHistory } from "react-router-dom";
import { fetchUserDetail } from "../../redux";

const BrandDetailsForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { successSnack, errorSnack } = useSnackDispatcher();
  const userData = useSelector((state) => state.userData.userData);
  const userAuthDetail = auth?.currentUser;
  const [brandName, setBrandName] = useState("");
  const [industry, setIndustry] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [imagErrors, setImageErrors] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailId, setEmailId] = useState('');
  // const [eligibleUser, setEligibleUser] = useState([]);
  // const [eligibleUserSelection, setEligibleUserSelection] = useState([]);

  const [image, setImage] = useState(null);
  const { saveAvatar } = firebaseImageStore({ selectedFile, functionCall: saveProfileAvatar })

  useEffect(() => {
    if (userData) {
      setTargetAudience(userData?.audience)
      setIndustry(userData?.industry)
      setWebsiteUrl(userData?.website_url)
      setBrandName(userData?.brand_name)
      setImage(userData?.avatar_url)
    }
  }, [userData])

  useEffect(() => {
    if (userAuthDetail?.email || userAuthDetail?.phoneNumber) {
      setMobileNumber(userAuthDetail?.phoneNumber || '')
      setEmailId(userAuthDetail?.email || '')
    } else if (userData.id) {
      setMobileNumber(userData?.contactNumber || '')
      setEmailId(userData?.email || '')
    }
  }, [userData.id, userAuthDetail]);

  useEffect(() => {

    // ---------------- target audience ----------

    // ApiService.get('offers_eligible').then((res) => {
    //   if (res?.status == 200 || res?.status == 201) {
    //     setEligibleUser(res?.data?.data)
    //   }
    // }).catch(error => {
    //   console.log("event:", error)
    // })

    ApiService.get('industryList').then((res) => {
      let response = res?.data?.data
      if (res?.status == 200 || res.status == 201) {
        setIndustryOptions(response)
      }
    }).catch(error => {
      console.log(error)
    })

  }, [])

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file)
    setImageErrors(false)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // ------------- image save to firebase storage and update api call -------

  // function saveProfileAvatar() {
  function saveProfileAvatar({ downloadURL }) {
    const formData = new FormData();

    if (downloadURL) {
      formData.append('avatar_url', downloadURL);
    }

    formData.append('brand_name', brandName);
    formData.append('contactNumber', mobileNumber);
    formData.append('email', emailId);
    formData.append('industry', industry?.id);
    formData.append('website_url', websiteUrl);
    formData.append('audience', targetAudience);
    setLoader(true)
    ApiService.put('saveProfile', formData, { id: userData.id }).then((res) => {
      if (res.status == 200 || res.status == 201) {
        successSnack(formSnackbar?.profileSuccess?.successMessage)
        history.push('/profile-completion')
      }
      setLoader(false)
      dispatch(fetchUserDetail());
    }).catch(error => {
      errorSnack(formSnackbar.errors.errorMsg)
      setLoader(false)
      console.log(error)
    })
  }

  // --------------- validation handler ----------

  function validationInputs() {
    let errors = {};
    let isValid = true;
    if (image == '' || image == null && (!selectedFile || selectedFile == null)) {
      setImageErrors(true);
      isValid = false;
    }
    if (brandName?.trim() === '' || !regexValidator(brandName, 'textSpaceEndStart')) {
      errors.brandName = "Brand name is required and must be valid.";
      isValid = false;
    }
    if (!industry) {
      errors.industry = "Please select an industry.";
      isValid = false;
    }
    if (websiteUrl?.trim() === '' || !regexValidator(websiteUrl, 'URL')) {
      errors.websiteUrl = "Enter a valid website URL.";
      isValid = false;
    }
    if (targetAudience === '') {
      errors.targetAudience = "Target Audience is required.";
      isValid = false;
    }

    setErrors(errors); // Store errors in state
    return isValid;
  }

  // ------------- submit handler ---------

  function submitProfile(e) {
    e.preventDefault();
    let isValid = validationInputs()
    if (isValid) {
      if (!selectedFile || selectedFile == null) {
        saveProfileAvatar({})
      } else {
        saveAvatar()
      }
    }
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px', color: '#454545' }} >Gather brand details for their profile and public visibility</Typography>

      {/* Logo Upload */}
      <Box style={{ display: "flex", alignItems: "center", gap: "10px", }} my={3} >
        {/* Avatar Preview */}

        <Box
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            overflow: "hidden",
            border: "2px dashed #ccc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f9f9f9",
          }}
        >
          {image ? (
            <img src={image} alt="Avatar Preview" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
          ) : (
            <CloudUploadIcon style={{ fontSize: "40px", color: "#ccc" }} />
          )}
        </Box>

        <label htmlFor="upload-avatar" style={{ cursor: "pointer", position: "relative" }}>
          <input
            type="file"
            id="upload-avatar"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />  {/* Upload Text */}
          <Typography variant="h6" color="error" style={{ fontWeight: "bold", color: '#FF4D49', fontSize: '14px' }}>
            Logo Upload
          </Typography>
        </label>
      </Box>
      {imagErrors ? <span className="text-danger ">Please select logo</span> : null}

      {/* Form Inputs */}

      <ReusableComponent
        type="text"
        label="Brand name"
        value={brandName}
        onChange={setBrandName}
        error={!!errors.brandName}
        helperText={errors.brandName}
        placeholder="Brand name"
      />
      <ReusableComponent
        type="select"
        label="Industry"
        options={industryOptions}
        getOptionLabel={(option) => option.industry_name}
        value={industry}
        onChange={setIndustry}
        error={!!errors.industry}
        helperText={errors.industry}
        placeholder="Industry"
      />
      <ReusableComponent type="numeric"
        label="Mobile number"
        value={mobileNumber}
        InputProps={{
          endAdornment: userAuthDetail?.phoneNumber || mobileNumber ?
            <VerifiedIcon position="start" size="small" sx={{ color: '#F14950' }} />
            : null
        }}
        disabledValue={userAuthDetail?.phoneNumber || mobileNumber ? true : false} />
      <ReusableComponent
        type="text"
        label="Website url"
        value={websiteUrl}
        onChange={setWebsiteUrl}
        error={!!errors.websiteUrl}
        helperText={errors.websiteUrl}
        placeholder="Website url"
      />

      <ReusableComponent
        type="text"
        label="Target audience"
        value={targetAudience}
        onChange={setTargetAudience}
        error={!!errors.targetAudience}
        helperText={errors.targetAudience}
        placeholder="Target audience"
      />

      {/* <ReusableComponent
        type="select"
        label="Target audience"
        value={targetAudience}
        onChange={setTargetAudience}
        error={!!errors.targetAudience}
        helperText={errors.targetAudience}
        placeholder="Target audience"
        options={eligibleUser}
        getOptionLabel={(option) => option.TypeName}
      /> */}


      {/* Next Button */}

      <Box textAlign={'end'}>
        <ReusableButton variant="contained" classNames={'gn-actionbtn'} children={'Save'} onClick={submitProfile} loader={loader} />
      </Box>
      <PhoneVerificationDialog />
    </Box>
  );
};

export default BrandDetailsForm;
