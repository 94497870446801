
import { Box, Grid, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReusableCard from "../reusableComponents/ReusableCard";
import ReusableHeadingComponents from "../reusableComponents/reusableHeadingComponents";
import ReusableButton from "../reusableComponents/reusableButtonComponents";
import ApiService from "../../_services/ApiService";
import { CardSkeletonCollab } from "../../components/reusableComponents/SkeletonCard";
import NoResults from "../../components/reusableComponents/NoResults";
import { useHistory } from "react-router-dom";
import { formatDaysAgo } from "../../_helpers/reusablefunctions/dateSetter";

const InternshipManagement = () => {
    const history = useHistory();
    const [internshipManagement, setInternshipManagement] = useState([])
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    // ------------------ Event List Get API Call 

    useEffect(() => {
        setLoader(true)
        ApiService.get('internships', { offset: page, limit: rowsPerPage }).then((res) => {
            if (res?.status == 200 || res?.status == 201) {
                setInternshipManagement(res?.data?.data)
                setCount(res?.data?.count)
            }
            setLoader(false)
        }).catch(error => {
            setLoader(false)
            console.log("event:", error)
        })
    }, [page, rowsPerPage])

    // -----------------

    function openSubmission(params) {
        history.push('/internship/create-internship')
    }

    const handleChangePage = (event, newPage) => setPage(newPage);
    const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));


    return (
        <Box p={4}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <ReusableHeadingComponents heading={'Internship Posting and Management'} subHeading={'Kickstart Your Career with Hands-On Experience – Apply for Our Internship Program Today'} />
                <ReusableButton children={'Create internship'} variant="contained"
                    classNames={'gn-actionbtn'} onClick={openSubmission} />
            </Box>
            <Box mb={4}>
                <Grid container spacing={2}>
                    {loader ? (
                        ['', '', '', ''].map((d, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                <CardSkeletonCollab />
                            </Grid>
                        ))
                    ) : internshipManagement.length ? (
                        internshipManagement.map((data, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <ReusableCard
                                    interImage={data?.Featured_Banner || data?.Title}
                                    title={data?.Title || ''}
                                    description={data?.SubTitle || ''}
                                    amount={data?.AmountMonthly || ''}
                                    location={data?.Location || ''}
                                    internshipdate={formatDaysAgo(data?.created_at)}
                                    primaryAction={{ label: "" }}
                                    secondaryAction={{ label: "View", onClick: () => history.push('/internship/view-internship', { eventData: data }) }}
                                />
                            </Grid>
                        ))
                    ) : (
                        <NoResults />
                    )}
                </Grid>
            </Box>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ '& p': { margin: '0px' } }}
            />
        </Box>
    );
};

export default InternshipManagement;
