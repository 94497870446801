import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export function CardSkeleton() {
    return (
        <div className="box-card">
            <Skeleton variant="rectangular" height={200} sx={{ margin: '1rem', borderRadius: '8px' }} />
        </div>
    );
}

export function CardSkeletonCollab() {
    return (
        <div className="bg-white rounded mt-2 w-100 d-flex flex-column">
            <Skeleton variant="rectangular" height={200} width={250} sx={{ margin: '1rem', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" height={20} width={250} sx={{ margin: '0.2rem 1rem', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" height={20} width={250} sx={{ margin: '0.2rem 1rem', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" height={20} width={250} sx={{ margin: '0.2rem 1rem', borderRadius: '8px' }} />

        </div>
    );
}

export function CardSkeletonCount() {
    return (
        <div className="bg-white rounded mt-2 w-100 d-flex flex-column">
            <Skeleton variant="rectangular" height={100} width={250} sx={{ margin: '1rem', borderRadius: '8px' }} />

        </div>
    );
}

